import { FC, ReactNode } from 'react';
import styles from './Pagination.module.scss';
import classNames from 'classnames';
import { ReactComponent as IcArrowPrev } from '../../assets/images/icons/arrow_prev.svg';
import { ReactComponent as IcArrowNext } from '../../assets/images/icons/arrow_next.svg';

interface PaginationProps {
	children: ReactNode;
	className?: string;
}

interface PaginationItemProps {
	children: ReactNode;
	active: boolean;
	onClick?: () => void;
	className?: string;
}

interface PaginationPrevProps {
	onClick?: () => void;
}

interface PaginationNextProps {
	onClick?: () => void;
}

const Pagination: FC<PaginationProps> & { Item: FC<PaginationItemProps> } & {
	Prev: FC<PaginationPrevProps>;
} & { Next: FC<PaginationNextProps> } = ({ children, className }) => (
	<ul className={classNames(styles.pagination, className)}>{children}</ul>
);

Pagination.Item = ({ children, active, onClick, className }) => (
	<li
		onClick={onClick}
		className={classNames(styles.paginationItem, active && styles.active)}
	>
		<span className={classNames(styles.pageLink, className)} role={'button'}>
			{children}
		</span>
	</li>
);

Pagination.Prev = ({ onClick }) => (
	<li
		onClick={onClick}
		className={classNames(styles.paginationItem, styles.paginationPrev)}
	>
		<span className={classNames(styles.pageLink)} role={'button'}>
			<IcArrowPrev />
		</span>
	</li>
);

Pagination.Next = ({ onClick }) => (
	<li
		onClick={onClick}
		className={classNames(styles.paginationItem, styles.paginationNext)}
	>
		<span className={classNames(styles.pageLink)} role={'button'}>
			<IcArrowNext />
		</span>
	</li>
);

export default Pagination;
