import document from '../../assets/images/document.png';

export const accordionData = [
	{
		title:
			'Свидетельство о постановке на учет российской организации в налоговом органе по месту нахождения',
		content: `<img src='${document}' alt='img'/>`
	},
	{
		title: 'Свидетельство о государственной регистрации юридического лица',
		content: `<img src='${document}' alt='img'/>`
	},
	{
		title: 'Устав акционерного общества «Сахалинское ипотечное агентство»',
		content: `<img src='${document}' alt='img'/>`
	},
	{
		title: 'Годовой отчет за 2019 год',
		content: `<img src='${document}' alt='img'/>`
	},
	{
		title: 'Годовой отчет за 2018 год',
		content: `<img src='${document}' alt='img'/>`
	},
	{
		title: 'Годовой отчет за 2017 год',
		content: `<img src='${document}' alt='img'/>`
	},
	{
		title: 'Годовой отчет за 2016 год',
		content: `<img src='${document}' alt='img'/>`
	},
	{
		title: 'Годовой отчет за 2015 год',
		content: `<img src='${document}' alt='img'/>`
	},
	{
		title:
			'Сводная ведомость результатов проведения специальной оценки условий труда',
		content: `<img src='${document}' alt='img'/>`
	},
	{
		title:
			'Политика обработки персональных данных АО «Сахалинское ипотечное агентство»',
		content: `<img src='${document}' alt='img'/>`
	}
];
