import styles from '../AdditionalInfo/AdditionalInfo.module.scss';

const Info = () => {
	return (
		<div>
			<h1 className={styles.heading}>Информация об объекте</h1>
			<div className={styles.container}>
				<div>
					<p>
						<span>Общая площадь:</span> 42.2 м²
					</p>
					<p>
						<span>Жилая площадь:</span> 39.07 м²
					</p>
					<p>
						<span>Площадь кухни:</span> 10.7 м²
					</p>
					<p>
						<span>Планировка:</span> Смежная
					</p>
				</div>
				<div>
					<p>
						<span>Этаж:</span> 2 из 4
					</p>
					<p>
						<span>Материал стен:</span> Монолит
					</p>
					<p>
						<span>Высота потолков:</span> 2.8 м
					</p>
					<p>
						<span>Год постройки:</span> 2022
					</p>
				</div>
			</div>
		</div>
	);
};

export default Info;
