import { ReactComponent as IcHomeQuest } from '../../assets/images/icons/home_quest.svg';
import { ReactComponent as IcPaper } from '../../assets/images/icons/paper.svg';
import { ReactComponent as IcNews } from '../../assets/images/icons/news.svg';

export const saleImmovableData = [
	{
		title: 'Документы и Заявления',
		subtitle: 'Информация для заемщиков',
		icon: <IcPaper />,
		path: '/documents'
	},
	{
		title: 'Новости',
		subtitle: 'Читайте наш интересный блог',
		icon: <IcNews />,
		path: '/allNews'
	},
	{
		title: 'Вопросы',
		subtitle: '100+ вопросов',
		icon: <IcHomeQuest />,
		path: '/'
	}
];
