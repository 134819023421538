import { FC } from 'react';
import styles from './DownloadBtn.module.scss';
import classNames from 'classnames';

interface DownloadBtnProps {
	className?: string;
	onClick?: () => void;
}

const DownloadBtn: FC<DownloadBtnProps> = ({ className, onClick }) => {
	return (
		<button
			className={classNames(styles.downloadBtn, className)}
			onClick={onClick}
		>
			Скачать документ
		</button>
	);
};

export default DownloadBtn;
