import { FC } from 'react';
import styles from './ConditionCard.module.scss';

interface ConditionCardProps {
	title: string;
	subtitle: string;
}

const ConditionCard: FC<ConditionCardProps> = ({ title, subtitle }) => {
	return (
		<div className={styles.card}>
			<h3>{title}</h3>
			<p>{subtitle}</p>
		</div>
	);
};

export default ConditionCard;
