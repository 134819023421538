import Main from '../../containers/Main/Main';
import SectionWrapper from '../../containers/SectionWrapper/SectionWrapper';
import Row from '../../components/Row/Row';
import AccentButton from './ui/AccentButton/AccentButton';
import ConditionRow from './components/ConditionRow/ConditionRow';
import { conditionData, goalLoan } from './EasternMortgage.data';
import ConditionCard from './components/ConditionCard/ConditionCard';
import CardsRow from '../../containers/CardsRow/CardsRow';
import NumberCard from '../../components/NumberCard/NumberCards';
import { SwiperSlide, Swiper } from 'swiper/react';
import { categoryData } from '../AboutCompany/Category.data';
import CategoryCardPost from '../../components/CategoryCardPost/CategoryCardPost';
import Wrapper from '../../containers/Wrapper/Wrapper';

const EasternMortgage = () => {
	return (
		<Main>
			<SectionWrapper pbNone>
				<h1 className={'titleHeader'}>Дальневосточная ипотека</h1>

				<h2 className={'mb-6'}>Важная информация</h2>

				<Row>
					<Row.Header>
						<h2>Дальневосточная ипотека</h2>
					</Row.Header>
					<Row.Content>
						<p>
							Информируем вас о запуске нового сайта «Дальневосточная ипотека в
							Сахалинской области»:{' '}
							<a href='https://ipoteka.sakhalin.gov.ru'>
								https://ipoteka.sakhalin.gov.ru
							</a>{' '}
							(Главная страница сайта Правительства Сахалинской области, левая
							колонка баннеров, под баннером «Нефть и Газ Сахалина»),
							разработанного в соответствии с поручением Губернатора Сахалинской
							области Лимаренко В. И. в целях информирования жителей Сахалинской
							области о программе «Дальневосточная ипотека».
						</p>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper>
				<Row>
					<Row.Header>
						<h2>Что такое «Дальневосточная ипотека»</h2>
					</Row.Header>
					<Row.Content>
						<p>
							Начиная с 07 декабря 2019 года по 31 декабря 2024 года на
							территории Дальневосточного Федерального округа в соответствии с
							постановлением Правительства Российской Федерации действует
							программа по выдаче гражданам Российской федерации (независимо от
							места регистрации) льготных ипотечных кредитов «Дальневосточная
							ипотека» для приобретения (в том числе строительства) жилья на
							территории Дальневосточного федерального округа. Особенностью
							программы является беспрецедентно низкая процентная ставка на весь
							срок кредитования – 2% годовых на срок до 242 месяцев. Общие
							условия программы «Дальневосточная ипотека»:
						</p>

						<AccentButton path={'/'} className={'mt-6'}>
							Перейти на калькулятор
						</AccentButton>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper pbNone>
				<h1 className={'mb-6'}>Лица, имеющие право на получение кредита</h1>

				<Row>
					<Row.Header>
						<h2>Условия</h2>
					</Row.Header>
					<Row.Content>
						<p className={'mb-6'}>
							<span>Физические лица – граждане Российской Федерации:</span>
						</p>

						<ConditionRow>
							{conditionData.map((item, index) => {
								return (
									<ConditionCard
										key={index}
										title={item.title}
										subtitle={item.subtitle}
									/>
								);
							})}
						</ConditionRow>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper pbNone>
				<Row>
					<Row.Header>
						<h2>Цель кредита</h2>
					</Row.Header>
					<Row.Content>
						<CardsRow>
							{goalLoan.map((item, index) => {
								return (
									<NumberCard
										key={index}
										number={item.number}
										text={item.text}
									/>
								);
							})}
						</CardsRow>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper>
				<Row className={'mb-5'}>
					<Row.Header>
						<h2>Сумма кредита</h2>
					</Row.Header>
					<Row.Content>
						<p>до 6 000 000 ₽</p>
					</Row.Content>
				</Row>

				<Row className={'mb-5'}>
					<Row.Header>
						<h2>Срок кредита</h2>
					</Row.Header>
					<Row.Content>
						<p>
							Минимальный срок: 36 месяцев.
							<br />
							Максимальный: 242 месяца.
						</p>
					</Row.Content>
				</Row>

				<Row className={'mb-5'}>
					<Row.Header>
						<h2>Первоначальный взнос</h2>
					</Row.Header>
					<Row.Content>
						<p>
							<span className={'color-accent'}>Не менее 15%</span> от стоимости
							приобретаемого (строящегося) жилого помещения (может быть оплачен
							за счет средств материнского (семейного) капитала)
						</p>
					</Row.Content>
				</Row>

				<Row className={'mb-5'}>
					<Row.Header>
						<h2>Страхование</h2>
					</Row.Header>
					<Row.Content>
						<p>
							Имущественное страхование – обязательное требование,
							<br />
							Личное и титульное страхование — по желанию заемщика.
						</p>
					</Row.Content>
				</Row>

				<Row className={'mb-5'}>
					<Row.Header>
						<h2>Расчет размера ежемесячного платежа</h2>
					</Row.Header>
					<Row.Content>
						<p>
							При сумме кредита 6 млн. руб. на срок 242 календарных месяца
							ежемесячный платеж составит 30 353 руб*. (без учета дополнительных
							расходов, связанных с обязательным страхованием предмета залога).
							<br />
							<br />
							<span className={'color-gray'}>
								*- расчет носит информационный характер, точный размер платежа
								может быть рассчитан непосредственно при заключении кредитного
								договора
							</span>
						</p>
					</Row.Content>
				</Row>

				<Row className={'mb-5'}>
					<Row.Header>
						<h2>Требования к стажу работы</h2>
					</Row.Header>
					<Row.Content>
						<p>
							Работники по найму — общий стаж работы не менее 1 года , стаж
							работы на последнем месте не менее 3 месяцев (испытательный срок
							должен быть пройден или отсутствовать). Для ИП и собственников
							бизнеса — безубыточная предпринимательская деятельность не менее
							24 месяцев.*
							<br />
							<br />
							На момент обращения за кредитом потенциальный заемщик должен быть
							официально трудоустроен, что подтверждается справкой о доходах по
							форме 2-НДФЛ или по форме Кредитора (за последние 12 месяцев, либо
							за фактически отработанное время при работе на текущем месте менее
							12 месяцев, но не менее 3 полных календарных месяцев после
							окончания испытательного срока).*
							<br />
							<br />
							<span className={'color-gray'}>
								*- требования могут отличаться у разных банков
							</span>
						</p>
					</Row.Content>
				</Row>

				<Row>
					<Row.Header>
						<h2>Дополнительные условия</h2>
					</Row.Header>
					<Row.Content>
						<p>
							Гражданину предоставляется право воспользоваться льготным кредитом
							по программе Дальневосточная ипотека» только один раз- в качестве
							заемщика или созаемщика по кредитному договору.
							<br />
							<br />
							Кредит не может быть предоставлен на погашение ранее полученных
							ипотечных кредитов или займов.
						</p>

						<AccentButton path={'/'} className={'mt-6'}>
							Перейти на калькулятор
						</AccentButton>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper pbNone>
				<h1 className={'mb-6'}>Другие разделы</h1>

				<Wrapper>
					<Swiper
						spaceBetween={20}
						slidesPerView={'auto'}
						scrollbar={{ draggable: true }}
					>
						{categoryData.map((item, index) => {
							return (
								<SwiperSlide style={{ width: 'auto' }} key={index}>
									<CategoryCardPost
										icon={item.icon}
										title={item.title}
										subtitle={item.subtitle}
										path={item.path}
									/>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</Wrapper>
			</SectionWrapper>
		</Main>
	);
};

export default EasternMortgage;
