import { FC, useState } from 'react';
import styles from './Input.module.scss';
import generateTranslit from '../../../../core/utils/generateTranslit';
import RoubleIcon from '../../../RoubleIcon/RoubleIcon';
import { ReactComponent as EyeIcon } from '../../../../assets/images/icons/eyeIcon.svg';
import { ReactComponent as ClosedEyeIcon } from '../../../../assets/images/icons/closedEye.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/images/icons/calendarIcon.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/images/icons/uploadIcon.svg';

export interface IInput {
	label: string;
	type: string;
	required?: boolean;
	description?: string;
	file?: boolean;
	options?: Array<string>;
	checkbox?: string;
	money?: boolean;
	placeholder?: string;
}

const Input: FC<IInput> = ({
	label,
	type,
	required,
	description,
	file,
	checkbox,
	options,
	money,
	placeholder
}) => {
	const [isClosed, setIsClosed] = useState(true);
	const [textAreaHeight, setTextAreaHeight] = useState(1);
	const id = generateTranslit(label);
	let checkboxId;
	if (checkbox) checkboxId = generateTranslit(checkbox);

	function handleChange(event) {
		console.log(event.target.rows);
		const height = event.target.scrollHeight;
		const rowHeight = 27;
		const trows = Math.ceil(height / rowHeight) - 1;

		setTextAreaHeight(trows);
	}

	return (
		<>
			{type === 'text' && (
				<div className={styles.container}>
					<div className={styles.inputContainer}>
						<label htmlFor={id} className={styles.label}>
							{label} {required && <span className={styles.required}>*</span>}
						</label>
						<input
							id={id}
							type={type}
							className={styles.input}
							placeholder={placeholder}
						/>
						{money && <RoubleIcon className={styles.icon} />}
					</div>

					{description && <p className={styles.description}>{description}</p>}
					{checkbox && (
						<div>
							<input
								id={checkboxId}
								type={'checkbox'}
								name={checkbox}
								className={styles.checkBox}
								value={checkbox}
							/>
							<label htmlFor={checkboxId}>{checkbox}</label>
						</div>
					)}
				</div>
			)}
			{type === 'password' && (
				<div className={styles.container}>
					<div className={styles.inputContainer}>
						<label htmlFor={id} className={styles.label}>
							<p>
								{label} {required && <span className={styles.required}>*</span>}
							</p>
						</label>

						<div className={styles.inputContainer}>
							<input
								id={id}
								type={isClosed ? 'password' : 'text'}
								className={styles.input}
							/>
						</div>
						<span
							className={styles.icon}
							onClick={() => setIsClosed(prev => !prev)}
						>
							{isClosed ? <EyeIcon /> : <ClosedEyeIcon />}
						</span>
					</div>
					{description && <p className={styles.description}>{description}</p>}
					{checkbox && (
						<div>
							<input
								id={checkboxId}
								type={'checkbox'}
								name={checkbox}
								className={styles.checkBox}
								value={checkbox}
							/>
							<label htmlFor={checkboxId}>{checkbox}</label>
						</div>
					)}
				</div>
			)}
			{type === 'checkbox' && options && (
				<div className={styles.container}>
					<p>
						{label} {required && <span className={styles.required}>*</span>}
					</p>
					{options.map(option => (
						<div key={option}>
							<input
								id={generateTranslit(option)}
								type={'checkbox'}
								name={option}
								className={styles.checkBox}
								value={option}
							/>
							<label htmlFor={generateTranslit(option)}>{option}</label>
						</div>
					))}
					{description && <p className={styles.description}>{description}</p>}
				</div>
			)}
			{type === 'data' && (
				<div className={styles.container}>
					<div className={styles.inputContainer}>
						<label htmlFor={id} className={styles.label}>
							<p>
								{label} {required && <span className={styles.required}>*</span>}
							</p>
						</label>
						<input
							id={id}
							type={type}
							className={styles.input}
							placeholder={placeholder}
						/>
						<span className={styles.icon}>
							<CalendarIcon />
						</span>
					</div>
					{description && <p className={styles.description}>{description}</p>}
					{checkbox && (
						<div>
							<input
								id={checkboxId}
								type={'checkbox'}
								name={checkbox}
								className={styles.checkBox}
								value={checkbox}
							/>
							<label htmlFor={checkboxId}>{checkbox}</label>
						</div>
					)}
				</div>
			)}
			{type === 'file' && (
				<div className={styles.container}>
					<p>
						{label} {required && <span className={styles.required}>*</span>}
					</p>
					<label htmlFor={id} className={styles.label}>
						<input id={id} type={type} className={styles.fileInput} />
						<div className={styles.fileButton}>
							<UploadIcon />
							<p>{file ? 'Загрузить файлы' : 'Загрузить фото'}</p>
						</div>
					</label>
					{description && <p className={styles.description}>{description}</p>}
					{checkbox && (
						<div>
							<input
								id={checkboxId}
								type={'checkbox'}
								name={checkbox}
								className={styles.checkBox}
								value={checkbox}
							/>
							<label htmlFor={checkboxId}>{checkbox}</label>
						</div>
					)}
				</div>
			)}
			{type === 'textarea' && (
				<div className={styles.container}>
					<label htmlFor={label}>
						<p>
							{label} {required && <span className={styles.required}>*</span>}
						</p>
					</label>
					<textarea
						onChange={handleChange}
						id={id}
						name={id}
						rows={textAreaHeight}
						className={styles.textarea}
					></textarea>

					{description && <p className={styles.description}>{description}</p>}
					{checkbox && (
						<div>
							<input
								id={checkboxId}
								type={'checkbox'}
								name={checkbox}
								className={styles.checkBox}
								value={checkbox}
							/>
							<label htmlFor={checkboxId}>{checkbox}</label>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default Input;
