import styles from './LogoLight.module.scss';
import logoLight from '../../assets/images/logo/logo-light.svg';

const LogoDark = () => {
	return (
		<a href={'/'} className={styles.logo}>
			<img src={logoLight} alt='Логотип компании' />
		</a>
	);
};

export default LogoDark;
