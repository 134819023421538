import { FC, ReactNode } from 'react';
import styles from './DocumentsContent.module.scss';

interface DocumentsContentProps {
	children: ReactNode;
}

const DocumentsContent: FC<DocumentsContentProps> = ({ children }) => {
	return <div className={styles.content}>{children}</div>;
};

export default DocumentsContent;
