import { FC } from 'react';
import styles from './NewsCard.module.scss';

interface NewsCardProps {
	img: string;
	title: string;
	newsContent: string;
	date: string;
	path: string;
}

const NewsCard: FC<NewsCardProps> = ({
	img,
	title,
	newsContent,
	date,
	path
}) => {
	return (
		<article className={styles.newsCard}>
			<div className={styles.body}>
				<a className={styles.link} href={path}></a>
				<div className={styles.imgWrapper}>
					<img src={img} alt='img' />
				</div>

				<div className={styles.content}>
					<p className={styles.date}>{date}</p>
					<h2 className={styles.title}>{title}</h2>
					<p className={styles.newsContent}>{newsContent}</p>
				</div>
			</div>
		</article>
	);
};

export default NewsCard;
