import Main from '../../containers/Main/Main';
import SectionWrapper from '../../containers/SectionWrapper/SectionWrapper';
import Row from '../../components/Row/Row';
import Phone from '../../ui/Phone/Phone';
import map from '../../assets/images/map/map.png';
import { requisitesData } from './Requisites.data';
import Requisites from './components/Requisites/Requisites';
import MainAccordion from '../../components/MainAccordion/MainAccordion';
import { accordionData } from './Accordion.data';
import DownloadBtn from '../../ui/DownloadBtn/DownloadBtn';
import { SwiperSlide, Swiper } from 'swiper/react';
import { categoryData } from './Category.data';
import Wrapper from '../../containers/Wrapper/Wrapper';
import CategoryCardPost from '../../components/CategoryCardPost/CategoryCardPost';
import Image from '../../components/Image/Image';

const AboutCompany = () => {
	return (
		<Main>
			<SectionWrapper pbNone>
				<h1 className={'titleHeader'}>О нашей компании</h1>

				<Row>
					<Row.Header>
						<h2>Полное фирменное наименование</h2>
					</Row.Header>
					<Row.Content>
						<p className={'mb-md-2'}>
							<span>
								Акционерное общество «Сахалинское ипотечное агентство».
							</span>
						</p>
					</Row.Content>
				</Row>

				<Row>
					<Row.Header>
						<h2>Сокращенное наименование</h2>
					</Row.Header>
					<Row.Content>
						<p className={'mb-5'}>
							<span>АО «СиА».</span>
						</p>

						<p>
							АО «Сахалинское ипотечное агентство» зарегистрировано 19 июня 2015
							года в Межрайонной инспекции Федеральной налоговой службы №1 по
							Сахалинской области.
						</p>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper>
				<Row>
					<Row.Header>
						<h2>Место нахождения</h2>

						<p>
							Сахалинская область, г. Южно-Сахалинск, ул. Дзержинского, д. 21
							оф. 219
						</p>

						<h2>Номер телефона</h2>
						<Phone className={'mb-2'} phone={'8 (4242) 312-532'} />
						<Phone className={'mb-5'} phone={'8 (4242) 312-531'} />

						<h2>Факс</h2>
						<Phone className={'mb-2 mb-md-5'} phone={'8 (4242) 312-533'} />
					</Row.Header>

					<Row.Content>
						<Image src={map} alt={'карта'} fluid />
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper>
				<Row>
					<Row.Header>
						<h1>Учредитель</h1>
					</Row.Header>
					<Row.Content>
						<p>
							Учредителем АО «Сахалинское ипотечное агентство» является
							Сахалинская область, в лице министерства имущественных и земельных
							отношений Сахалинской области в соответствии с распоряжением
							Правительства Сахалинской области от 05.06.2015 №255-р.
							<br />
							<br />
							Общество является коммерческой организацией, основной целью
							которого является организация системы жилищного ипотечного
							кредитования в Сахалинской области. Средства, получаемые от
							продажи жилья в строящихся ипотечных домах и от деятельности на
							рынке ипотечного кредитования, направляются на увеличение
							оборотных средств для дальнейшего инвестирования в жилищное
							строительство и увеличения объемов ипотечного кредитования жителей
							Сахалинской области.
						</p>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper>
				<Row>
					<Row.Header>
						<h1>Реквизиты</h1>
					</Row.Header>
					<Row.Content>
						<Requisites>
							{requisitesData.map((item, index) => {
								return (
									<Requisites.Item
										key={index}
										title={item.title}
										description={item.description}
									/>
								);
							})}
						</Requisites>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper>
				<Row>
					<Row.Header>
						<h1>Корпоративная информация</h1>
					</Row.Header>
					<Row.Content>
						<MainAccordion data={accordionData} />
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper>
				<Row>
					<Row.Header>
						<h1>Информация по закупкам</h1>
					</Row.Header>
					<Row.Content>
						<p className={'mb-2'}>Об утверждении перечня СМП</p>
						<DownloadBtn />
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper pbNone>
				<h1 className={'mb-6'}>Другие разделы</h1>

				<Wrapper>
					<Swiper
						spaceBetween={20}
						slidesPerView={'auto'}
						scrollbar={{ draggable: true }}
					>
						{categoryData.map((item, index) => {
							return (
								<SwiperSlide style={{ width: 'auto' }} key={index}>
									<CategoryCardPost
										icon={item.icon}
										title={item.title}
										subtitle={item.subtitle}
										path={item.path}
									/>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</Wrapper>
			</SectionWrapper>
		</Main>
	);
};

export default AboutCompany;
