import flat04 from '../../../../assets/images/flatImages/flat4.png';
import flat05 from '../../../../assets/images/flatImages/flat5.png';
import flat06 from '../../../../assets/images/flatImages/flat6.png';

export const purchaseAdsData = [
	{
		image: flat04,
		price: '12 300 000',
		address:
			'Сахалин, Южно-Сахалинск, р-н Пролетарский, просп. Николая Корыткова, 28',
		typeFlat: '3-к. квартира',
		squareFlat: '75 м²',
		floorFlat: '2/4 эт.',
		path: '/SellAdvert'
	},
	{
		image: flat05,
		price: '5 600 000',
		address:
			'Сахалин, Южно-Сахалинск, р-н Пролетарский, просп. Николая Корыткова, 28',
		typeFlat: '2-к. квартира',
		squareFlat: '55 м²',
		floorFlat: '1/4эт.',
		path: '/'
	},
	{
		image: flat06,
		price: '3 100 000',
		address:
			'Сахалин, Южно-Сахалинск, р-н Пролетарский, просп. Николая Корыткова, 28',
		typeFlat: '1-к. квартира',
		squareFlat: '32 м²',
		floorFlat: '3/5 эт.',
		path: '/SellAdvert'
	},
	{
		image: flat04,
		price: '15 000',
		address:
			'Сахалин, Южно-Сахалинск, р-н Пролетарский, просп. Николая Корыткова, 28',
		typeFlat: '1-к. квартира',
		squareFlat: '43 м²',
		floorFlat: '5/12 эт.',
		path: '/SellAdvert'
	},
	{
		image: flat05,
		price: '15 000',
		address:
			'Сахалин, Южно-Сахалинск, р-н Пролетарский, просп. Николая Корыткова, 28',
		typeFlat: '1-к. квартира',
		squareFlat: '43 м²',
		floorFlat: '5/12 эт.',
		path: '/SellAdvert'
	}
];
