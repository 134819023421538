import styles from './Services.module.scss';
import { useRef, useState } from 'react';
import SwiperCore from 'swiper';
import SectionWrapper from '../../../../containers/SectionWrapper/SectionWrapper';
import ServicesCard from './ServicesCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { servicesData } from './Services.data';
import SwiperPrev from '../../../../components/SwiperPrev/SwiperPrev';
import SwiperNext from '../../../../components/SwiperNext/SwiperNext';
import Wrapper from '../../../../containers/Wrapper/Wrapper';

const Services = () => {
	const swiperRef = useRef<SwiperCore>();

	const [swiperNavigationAllowed, setSwiperNavigationAllowed] = useState<any>({
		prevButtonDisabled: true,
		nextButtonDisabled: false
	});

	return (
		<SectionWrapper>
			<h1 className={'mb-5'}>Представленные сервисы</h1>

			<Wrapper>
				<Swiper
					spaceBetween={24}
					slidesPerView={'auto'}
					scrollbar={{ draggable: true }}
					onBeforeInit={(swiper: SwiperCore | undefined) => {
						swiperRef.current = swiper;
					}}
					onReachEnd={() =>
						setSwiperNavigationAllowed({
							...swiperNavigationAllowed,
							nextButtonDisabled: true
						})
					}
					onReachBeginning={() =>
						setSwiperNavigationAllowed({
							...swiperNavigationAllowed,
							prevButtonDisabled: true
						})
					}
					onSlideChange={() =>
						setSwiperNavigationAllowed({
							prevButtonDisabled: swiperRef?.current?.isBeginning,
							nextButtonDisabled: swiperRef?.current?.isEnd
						})
					}
				>
					{servicesData.map((item, index) => {
						return (
							<SwiperSlide style={{ width: 'auto' }} key={index}>
								<ServicesCard
									path={item.path}
									icon={item.icon}
									text={item.title}
								/>
							</SwiperSlide>
						);
					})}
				</Swiper>

				<SwiperPrev
					onClick={() => swiperRef.current?.slidePrev()}
					disabled={swiperNavigationAllowed.prevButtonDisabled}
					className={styles.pos}
				/>
				<SwiperNext
					onClick={() => swiperRef.current?.slideNext()}
					disabled={swiperNavigationAllowed.nextButtonDisabled}
					className={styles.pos}
				/>
			</Wrapper>
		</SectionWrapper>
	);
};

export default Services;
