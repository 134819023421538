import styles from './AdditionalInfo.module.scss';

const AdditionalInfo = () => {
	return (
		<div>
			<h1 className={styles.heading}>Дополнительная информация</h1>
			<div className={styles.container}>
				<div>
					<p>
						<span>Парковка:</span> Открытая
					</p>
					<p>
						<span>Ремонт:</span> Косметический
					</p>
					<p>
						<span>Сторона света:</span> Запад
					</p>
					<p>
						<span>Балкон:</span> Один
					</p>
					<p>
						<span>Санузел: </span>
						Совмещенный
					</p>
				</div>
				<div>
					<p>
						<span>Комуникации:</span> Кабельное ТВ, Интернет
					</p>
					<p>
						<span>Лифт:</span> Нет
					</p>
					<p>
						<span>Газ:</span> Есть
					</p>
					<p>
						<span>Охрана:</span> Нет
					</p>
				</div>
			</div>
		</div>
	);
};

export default AdditionalInfo;
