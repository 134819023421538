import { FC } from 'react';
import styles from './SeeAllLink.module.scss';
import classNames from 'classnames';

interface SeeAllProps {
	path: string;
	mobileNone?: boolean;
	desktopNone?: boolean;
}

const SeeAllLink: FC<SeeAllProps> = ({ path, mobileNone, desktopNone }) => {
	return (
		<a
			className={classNames(
				styles.seeAllLink,
				mobileNone && styles.mobileNone,
				desktopNone && styles.desktopNone
			)}
			href={path}
		>
			Смотреть все
		</a>
	);
};

export default SeeAllLink;
