import styles from './SectionWrapper.module.scss';
import { FC, ReactNode } from 'react';
import classNames from 'classnames';

// Есть пропсы paddingTop и paddingBottom, которые отвечают паддинги, если пропсы true, то паддинги есть и наоборот
interface SectionWrapperProps {
	children: ReactNode;
	className?: string;
	ptNone?: boolean;
	pbNone?: boolean;
}

const SectionWrapper: FC<SectionWrapperProps> = ({
	children,
	className,
	ptNone,
	pbNone
}) => {
	return (
		<section
			className={classNames(
				styles.section,
				ptNone && styles.topNone,
				pbNone && styles.bottomNone,
				className
			)}
		>
			{children}
		</section>
	);
};

export default SectionWrapper;
