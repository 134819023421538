import React, { FC, ReactNode } from 'react';
import styles from './FormButton.module.scss';
import classNames from 'classnames';
export interface IButton {
	children: ReactNode;
	className?: string;
}
const FormButton: FC<IButton> = ({ children, className }) => {
	return (
		<div>
			<button type={'submit'} className={classNames(className, styles.button)}>
				{children}
			</button>
		</div>
	);
};

export default FormButton;
