import SectionWrapper from '../../../../containers/SectionWrapper/SectionWrapper';
import styles from './SearchBox.module.scss';
import SearchSelect from '../../../../ui/SearchSelect/SearchSelect';

const firstOptions = [
	{ value: '1', label: 'Купить' },
	{ value: '2', label: 'Продать' }
];

const secondOptions = [
	{ value: '1', label: 'Квартиру' },
	{ value: '2', label: 'Дом' }
];

const thirdOptions = [
	{ value: '1', label: '1 комната' },
	{ value: '2', label: '2 комната' },
	{ value: '3', label: '3 комната' },
	{ value: '4', label: '4 комната' },
	{ value: '5', label: '5 комната' }
];

const SearchBox = () => {
	return (
		<SectionWrapper ptNone={true}>
			<div className={styles.searchBox}>
				<div className={styles.searchBoxContent}>
					<h1>Недвижимость в Сахалине</h1>

					<div className={styles.row}>
						<div className={styles.searchParameters}>
							<div className={styles.searchParameter}>
								<SearchSelect options={firstOptions} />
							</div>

							<div className={styles.searchParameter}>
								<SearchSelect options={secondOptions} />
							</div>

							<div className={styles.searchParameter}>
								<SearchSelect options={thirdOptions} />
							</div>
						</div>

						<button className={styles.searchBtn}>Показать объявления</button>
					</div>
				</div>

				<div className={styles.searchBoxMask}></div>
			</div>
		</SectionWrapper>
	);
};

export default SearchBox;
