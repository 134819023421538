import { FC, ReactNode } from 'react';
import styles from './DocumentsTitle.module.scss';

interface DocumentTitleProps {
	children: ReactNode;
}

const DocumentsTitle: FC<DocumentTitleProps> = ({ children }) => {
	return <div className={styles.titleBox}>{children}</div>;
};

export default DocumentsTitle;
