import { useRef, useState } from 'react';
import styles from './NewsContent.module.scss';
import SectionWrapper from '../../../../containers/SectionWrapper/SectionWrapper';
import classNames from 'classnames';
import { ReactComponent as IcArrowRight } from '../../../../assets/images/icons/arrow_right.svg';
import photo1 from '../../../../assets/images/newsImages/photos/photo1.png';
import photo2 from '../../../../assets/images/newsImages/photos/photo2.png';
import photo3 from '../../../../assets/images/newsImages/photos/photo3.png';
import SwiperCore from 'swiper';
import SwiperPrev from '../../../../components/SwiperPrev/SwiperPrev';
import SwiperNext from '../../../../components/SwiperNext/SwiperNext';
import { Swiper, SwiperSlide } from 'swiper/react';
import Wrapper from '../../../../containers/Wrapper/Wrapper';

const photos = [photo1, photo2, photo3, photo1, photo2, photo3];

const NewsContent = () => {
	const swiperRef = useRef<SwiperCore>();

	const [swiperNavigationAllowed, setSwiperNavigationAllowed] = useState<any>({
		prevButtonDisabled: true,
		nextButtonDisabled: false
	});

	return (
		<SectionWrapper>
			<div className={styles.wrapper}>
				<div className={classNames(styles.aSide, styles.side)}>
					<a className={styles.linkBack} href='/allNews'>
						<IcArrowRight />
						<i>Назад</i>
					</a>

					<h2 className={styles.title}>
						38 семей получили ключи от новых квартир в Корсакове
					</h2>

					<p className={styles.date}>28.02.2023</p>

					<p className={styles.text}>
						Четвертую новостройку по адресу Артиллерийская 48 возвели для
						привлекаемых специалистов и работников бюджетной сферы.
						<br />
						<br />
						Часть квартир в новостройке Сахалинское ипотечное агентство
						выставляет на продажу. В первую очередь там смогут приобрести жильё
						специалисты бюджетных направлений, обладатели материнского капитала,
						сертификатов на улучшение жилищных условий, участники программ
						льготного кредитования, например, Дальневосточной ипотеки.
						<br />
						<br />
						Спустя три месяца квартиры смогут приобрести все желающие.
						<br />
						<br />
						– Важно отметить, что жилой фонд мы предоставляем в найм как по
						льготной цене, которая составляет не более 50% от среднерыночной для
						специалистов, на которых представлено ходатайство от администрации
						муниципалитета, так и по полной стоимости для всех желающих
						арендовать квартиру. Так, льготная цена за однокомнатную квартиру
						площадью 40 квадратных метров не превышает 10 тысяч рублей в месяц,
						а за трехкомнатную площадью 77 «квадратов» не более 15 тысячи
						рублей. Для желающих стать собственниками этих квартир цена
						квадратного метра начинается от 106 480 рублей и при привлечении
						льготной ипотеки, платеж за такую квартиру составит не более 20
						тысяч рублей в месяц. При покупке квартиры также можно использовать
						любые меры поддержки. Подчеркну, что основная задача Сахалинского
						ипотечного агентства – обеспечить жителей Сахалинской области
						доступным и качественным жильём, – прокомментировала генеральный
						директор Сахалинского ипотечного агентства Елена Бондарева.
						<br />
						<br />
						В новой «восьмиэтажке» выполнен качественный и современный ремонт –
						новоселам остаётся только перевезти свои вещи. В квартирах
						установлены окна, двери, сантехника и электроприборы, выполнено
						остекление балконов, в санузлах уложен кафель, во всех помещениях
						наклеены обои, установлены натяжные потолки. Всего в этом новом
						микрорайоне Сахалинским ипотечным агентством построено 4 новых дома.
						Внутри дворовой территории расположилась уютная прогулочная зона,
						детская площадка. Из окон квартир открывается шикарный вид на море и
						на уютный, тихий двор, расположенный в отдалении шумных трасс.
						<br />
						<br />
						Ранее еще 24 семьи в соседнем доме заселились в арендный фонд по
						ходатайству от муниципалитета. Квартиры распределены среди
						специалистов здравоохранения, образования, правоохранительных
						органов, предприятий приоритетных отраслей экономики. В прошлом году
						в соседних двух домах более 90 квартир предоставлены в найм для
						таких же востребованных специалистов.
					</p>

					<div className={styles.photos}>
						<h3 className={styles.photosTitle}>Фотографии</h3>
					</div>

					<Wrapper>
						<Swiper
							spaceBetween={24}
							slidesPerView={'auto'}
							scrollbar={{ draggable: true }}
							onBeforeInit={(swiper: SwiperCore | undefined) => {
								swiperRef.current = swiper;
							}}
							onReachEnd={() =>
								setSwiperNavigationAllowed({
									...swiperNavigationAllowed,
									nextButtonDisabled: true
								})
							}
							onReachBeginning={() =>
								setSwiperNavigationAllowed({
									...swiperNavigationAllowed,
									prevButtonDisabled: true
								})
							}
							onSlideChange={() =>
								setSwiperNavigationAllowed({
									prevButtonDisabled: swiperRef?.current?.isBeginning,
									nextButtonDisabled: swiperRef?.current?.isEnd
								})
							}
						>
							{photos.map((item, index) => {
								return (
									<SwiperSlide style={{ width: 'auto' }} key={index}>
										<div className={styles.photoItem}>
											<img src={item} alt='' />
										</div>
									</SwiperSlide>
								);
							})}
						</Swiper>

						<SwiperPrev
							onClick={() => swiperRef.current?.slidePrev()}
							disabled={swiperNavigationAllowed.prevButtonDisabled}
							className={styles.pos}
						/>
						<SwiperNext
							disabled={swiperNavigationAllowed.nextButtonDisabled}
							onClick={() => swiperRef.current?.slideNext()}
							className={styles.pos}
						/>
					</Wrapper>
				</div>

				<div className={classNames(styles.bSide, styles.side)}>
					<div className={styles.contactsBox}>
						<h3>Будем рады Вам помочь!</h3>
						<p>Если остались вопросы, свяжитесь с нами</p>
						<a href='/' className={styles.contactsBtn}>
							Контакты
						</a>
					</div>
				</div>
			</div>
		</SectionWrapper>
	);
};

export default NewsContent;
