import styles from './Menu.module.scss';
import { ReactComponent as IcClose } from '../../assets/images/icons/close.svg';
import { useAppDispatch, useAppSelector } from '../../core/hooks/storeHook';
import classNames from 'classnames';
import { setMenuActive, setId } from '../../core/store/slices/MenuSlice';
import { menuData } from '../../core/data/menu.data';
import { v4 as uuidv4 } from 'uuid';
import CategoryCard from '../../components/CategoryCard/CategoryCard';
import React, { useEffect } from 'react';

const Menu = () => {
	const { isMenuActive, id } = useAppSelector(state => state.MenuSlice);
	const dispatch = useAppDispatch();

	const closeMenu = () => {
		dispatch(setMenuActive(false));
		dispatch(setId('null'));
		document.body.classList.remove('lock');
		document.body.style.paddingRight = '0px';
	};

	useEffect(() => {
		const keyDownHandler = (event: KeyboardEvent) => {
			const inputEvent =
				event as unknown as React.KeyboardEvent<HTMLInputElement>;
			if (inputEvent.code === 'Escape') {
				dispatch(setMenuActive(false));
				dispatch(setId('null'));
				document.body.classList.remove('lock');
				document.body.style.paddingRight = '0px';
			}
		};

		document.addEventListener('keydown', keyDownHandler);

		return () => {
			document.removeEventListener('keydown', keyDownHandler);
		};
	}, [dispatch]);

	return (
		<div
			onClick={closeMenu}
			className={classNames(styles.menuWrapper, isMenuActive && styles.visible)}
		>
			<div
				onClick={event => event.stopPropagation()}
				className={classNames(styles.menu, isMenuActive && styles.visible)}
			>
				{id !== null &&
					menuData.map(itemMenu => {
						if (id === itemMenu.id) {
							return (
								<div className={styles.menuContent} key={uuidv4()}>
									<h1>{itemMenu.title}</h1>

									<div className={styles.rowCard}>
										{itemMenu.categories.map(card => {
											return (
												<CategoryCard
													key={uuidv4()}
													icon={card.icon}
													title={card.title}
													subtitle={card.subtitle}
													path={card.path}
												/>
											);
										})}
									</div>
								</div>
							);
						} else {
							return null;
						}
					})}

				<button onClick={closeMenu} className={styles.closeBtn}>
					<i>
						<IcClose />
					</i>
				</button>
			</div>
		</div>
	);
};

export default Menu;
