import styles from './Requisites.module.scss';
import { FC, ReactNode } from 'react';

interface RequisitesProps {
	children: ReactNode;
}

interface RequisitesItemProps {
	title: string;
	description: string;
}

const Requisites: FC<RequisitesProps> & { Item: FC<RequisitesItemProps> } = ({
	children
}) => {
	return <div className={styles.requisites}>{children}</div>;
};

Requisites.Item = ({ title, description }) => (
	<div className={styles.item}>
		<div className={styles.title}>{title}</div>{' '}
		<div className={styles.description}>{description}</div>
	</div>
);

export default Requisites;
