import styles from './Description.module.scss';

const Description = () => {
	return (
		<div className={styles.container}>
			<h1>Описание</h1>
			<p>
				ПРОДАЖА ОТ ЗАСТРОЙЩИКА. ЕСТЬ РАЗНЫЕ ВАРИАНТЫ ПЛАНИРОВОК И НА РАЗНЫХ
				ЭТАЖАХ. Квартира евродвушка в новом доме.
				<br />
				ЖК «Медовый» оборудован собственной газовой котельной, Все квартиры с
				РЕМОНТОМ ПОД КЛЮЧ. Малоэтажный жилой комплекс, благоустроенная
				придомовая территория, большое количество парковочных мест.
				<br />
				Удобная транспортная доступность – 15 минут до центра города на
				общественном транспорте. Вся инфраструктура в шаговой доступности:
				фитнес-центр, супермаркет, детский сад,аптека , спортивные площадки и
				многое другое. Остановка в 3 минутах от дома.
			</p>
		</div>
	);
};

export default Description;
