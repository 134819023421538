import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import styles from './Gallery.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { FreeMode, Navigation, Thumbs } from 'swiper';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as IcArrow } from '../../../../assets/images/icons/slideArrow.svg';
import img1 from '../../../../assets/images/flatImages/sliderFlats/1.png';
import img2 from '../../../../assets/images/flatImages/sliderFlats/2.png';
import img3 from '../../../../assets/images/flatImages/sliderFlats/3.png';
import img4 from '../../../../assets/images/flatImages/sliderFlats/4.png';
import img5 from '../../../../assets/images/flatImages/sliderFlats/5.png';
import img6 from '../../../../assets/images/flatImages/sliderFlats/6.png';
import img7 from '../../../../assets/images/flatImages/sliderFlats/7.png';
import img8 from '../../../../assets/images/flatImages/sliderFlats/8.png';
import img9 from '../../../../assets/images/flatImages/sliderFlats/9.png';
import img10 from '../../../../assets/images/flatImages/sliderFlats/10.png';

const images = [
	img1,
	img2,
	img3,
	img4,
	img4,
	img5,
	img6,
	img7,
	img8,
	img9,
	img10
];

const Gallery = () => {
	const swiperRef = useRef<SwiperCore>();
	const [activeIndex, setActiveIndex] = useState(0);
	const [allSlides, setAllSlides] = useState<number | undefined>(1);

	useEffect(() => {
		const slides = getSlides();
		setAllSlides(slides);
	}, []);
	const setSlide = (index: number) => {
		if (swiperRef.current) {
			swiperRef.current.slideTo(index, 0.1);
		}
	};
	const getSlides = () => {
		if (swiperRef.current) {
			return swiperRef.current?.slides.length;
		}
	};
	const nextSlide = () => {
		if (swiperRef.current) {
			swiperRef.current.slideNext();
		}
	};
	const prevSlide = () => {
		if (swiperRef.current) {
			swiperRef.current.slidePrev();
		}
	};
	return (
		<div className={styles.container}>
			<Swiper
				modules={[FreeMode, Navigation, Thumbs]}
				className={styles.swiper}
				onBeforeInit={(swiper: SwiperCore | undefined) => {
					swiperRef.current = swiper;
				}}
				onActiveIndexChange={e => setActiveIndex(e.activeIndex)}
			>
				{images.map((img, index) => {
					return (
						<SwiperSlide
							key={img}
							className={styles.slide}
							style={{ background: `url(${img})` }}
						>
							<img src={img} draggable={false} alt={'Интерьер'} />
						</SwiperSlide>
					);
				})}
			</Swiper>

			<p className={styles.slideCounter}>
				{activeIndex + 1}/{allSlides}
			</p>

			<button onClick={prevSlide} className={styles.prevButton}>
				<IcArrow />
			</button>
			<button onClick={nextSlide} className={styles.nextButton}>
				<IcArrow />
			</button>
			<div className={styles.navigation}>
				{images.map((img, index) => (
					<div
						key={img}
						onClick={() => setSlide(index)}
						className={classNames({
							[styles.active]: activeIndex === index
						})}
					>
						<img src={img} alt={'Интерьер'} />
					</div>
				))}
			</div>
		</div>
	);
};

export default Gallery;
