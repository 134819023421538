import { ReactComponent as IcHomeQuest } from '../../assets/images/icons/home_quest.svg';
import { ReactComponent as IcPaper } from '../../assets/images/icons/paper.svg';
import { ReactComponent as IcNews } from '../../assets/images/icons/news.svg';

export const offersData = [
	{
		number: '01',
		text: 'Выкупим вашу квартиру по рыночной цене, даже она в ипотеке'
	},
	{
		number: '02',
		text: 'Обменяем вашу квартиру во вторничном или строящемся жилье на новую'
	},
	{
		number: '03',
		text: 'Сохраним ваше право проживать в вашей квартире до момента переезда'
	},
	{
		number: '04',
		text: 'Вернем часть средств от продажи вашей квартиры для обустройства приобретаемой'
	},
	{
		number: '05',
		text: 'Сохраним ваше право проживать в вашей квартире до момента переезда'
	},
	{
		number: '06',
		text: 'Вернем часть средств от продажи вашей квартиры для обустройства приобретаемой'
	}
];

export const questionsData = [
	{
		title: 'Сколько будет стоит найм квартиры?',
		content: `<p class="color-dark">Вместе с оценкой рыночной цены продажи вашей квартиры мы оценим и рыночную стоимость её найма. Первые три месяца - проживание бесплатно.</p>`
	},
	{
		title: 'Освободить квартиру необходимо в дату получения ключей от новой?',
		content: `<p class="color-dark">Вместе с оценкой рыночной цены продажи вашей квартиры мы оценим и рыночную стоимость её найма. Первые три месяца - проживание бесплатно.</p>`
	},
	{
		title: 'Нужно ли сниматься с регистрационного учета по месту жительства?',
		content: `<p class="color-dark">Вместе с оценкой рыночной цены продажи вашей квартиры мы оценим и рыночную стоимость её найма. Первые три месяца - проживание бесплатно.</p>`
	},
	{
		title:
			'Возможен ли обмен в отдаленных муниципалитетах, где рыночная стоимость жилья мала?',
		content: `<p class="color-dark">Вместе с оценкой рыночной цены продажи вашей квартиры мы оценим и рыночную стоимость её найма. Первые три месяца - проживание бесплатно.</p>`
	},
	{
		title:
			'Возможно ли обменять квартиру, при покупке которой использовался материнский капитал?',
		content: `<p class="color-dark">Вместе с оценкой рыночной цены продажи вашей квартиры мы оценим и рыночную стоимость её найма. Первые три месяца - проживание бесплатно.</p>`
	}
];

export const stepsData = [
	{
		number: '01',
		text: 'Выберем новостройку по вашему желанию и забронируем, зафиксировав цену продажи'
	},
	{
		number: '02',
		text: 'Определим рыночную стоимость вашей квартиры в течении трех дней бесплатно'
	},
	{ number: '03', text: 'Выкупим вашу квартиру по рыночной стоимости' },
	{ number: '04', text: 'Оформим или погасим ипотеку при ее наличии' },
	{
		number: '05',
		text: 'Переведем средства как взнос по ипотеке или полатим вашу новую квартиру'
	},
	{
		number: '06',
		text: 'Подпишем договор купли-продажи и “договор долевого участия” в один день'
	},
	{
		number: '07',
		text: 'Подпишем договор найма на период до переезда в приобретаемую квартиру'
	},
	{
		number: '08',
		text: 'И конечно же, поможем и организуем переезд в вашу новую квартиру'
	}
];

export const categoryData = [
	{
		title: 'Документы и Заявления',
		subtitle: 'Информация для заемщиков',
		icon: <IcPaper />,
		path: '/documents'
	},
	{
		title: 'Новости',
		subtitle: 'Читайте наш интересный блог',
		icon: <IcNews />,
		path: '/allNews'
	},
	{
		title: 'Вопросы',
		subtitle: '100+ вопросов',
		icon: <IcHomeQuest />,
		path: '/'
	}
];
