import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MenuState {
	isMenuActive: boolean;
	id: string | null;
	isMobileMenuActive: boolean;
}

const initialState: MenuState = {
	isMenuActive: false,
	isMobileMenuActive: false,
	id: null
};

const MenuSlice = createSlice({
	name: 'MenuSlice',
	initialState,
	reducers: {
		setMenuActive(state, action: PayloadAction<boolean>) {
			state.isMenuActive = action.payload;
		},
		setMenuMobileActive(state, action: PayloadAction<boolean>) {
			state.isMobileMenuActive = action.payload;
		},
		setId(state, action: PayloadAction<string>) {
			state.id = action.payload;
		}
	}
});

export const { setMenuActive, setMenuMobileActive, setId } = MenuSlice.actions;
export default MenuSlice.reducer;
