export const homeBuyoutData = [
	{
		number: '01',
		text: 'Подача запроса с полным пакетом Документов',
		helpText: 'В первый же день'
	},
	{
		number: '02',
		text: 'Подтверждение документов Агенством',
		helpText: 'До 15 рабочих дней'
	},
	{
		number: '03',
		text: 'Подписание договора купли-продажи',
		helpText: 'До 20 рабочих дней'
	},
	{
		number: '04',
		text: 'Регистрация права собственности',
		helpText: 'До 10 рабочих дней'
	}
];
