import { ReactComponent as IcHomeQuest } from '../../assets/images/icons/home_quest.svg';
import { ReactComponent as IcPaper } from '../../assets/images/icons/paper.svg';
import { ReactComponent as IcNews } from '../../assets/images/icons/news.svg';

export const conditionData = [
	{
		title: 'Категория «Молодая семья»',
		subtitle:
			'Граждане, не достигшие возраста 36 лет, состоящие в браке между собой. Супруг(-а) заемщика, в обязательном порядке привлекается в качестве солидарного заемщика по кредитному договору. Граждане, не достигшие возраста 36 лет, не состоящие в браке, имеющие ребенка-гражданина Российской Федерации, возраст которого не достиг на дату заключения кредитного договора возраста 19 лет.'
	},
	{
		title:
			'Категория «Участник программы повышения мобильности трудовых ресурсов»',
		subtitle:
			'Гражданин Российской Федерации, переехавший на работу из других субъектов Российской Федерации в рамках реализации региональных программ повышения мобильности трудовых ресурсов в субъекты Российской Федерации, входящие в состав Дальневосточного федерального округа.'
	},
	{
		title: 'Участники программы «Дальневосточный гектар»',
		subtitle:
			'Желающие построить жилой дом для индивидуального проживания на «Дальневосточном гектаре», имеющим вид разрешенного использования «индивидуальное жилищное строительство» или «ведение личного подсобного хозяйства». Жилой дом должен строиться на земельном участке одного из заемщиков. В залог оформляется имеющаяся в собственности любого из заемщиков квартира или апартаменты.'
	},
	{
		title: 'Категория «Педагогический работник»',
		subtitle:
			'«Сотрудник государственной или муниципальной медицинской организации» проработавшие не менее 5 лет в государственной или муниципальной организации, расположенной на территории субъекта РФ, входящего в состав ДФО. Для данной категории граждан требования по возрасту и семейному положению применяться не будут.'
	}
];

export const goalLoan = [
	{
		number: '01',
		text: 'Квартира по договору купли-продажи от юридического лица — первого собственника (за исключением управляющей компании инвестиционного фонда)'
	},
	{
		number: '02',
		text: 'Квартира по договору участия в долевом строительстве от юридического лица (за исключением управляющей компании инвестиционного фонда),'
	},
	{
		number: '03',
		text: 'Квартира или жилой дом с земельным участком в сельских поселениях по договору купли-продажи от юридического или физического лица'
	},
	{
		number: '04',
		text: 'Строительство индивидуального жилого дома на земельном участке (под залог имеющейся недвижимости)'
	}
];

export const categoryData = [
	{
		title: 'Документы и Заявления',
		subtitle: 'Информация для заемщиков',
		icon: <IcPaper />,
		path: '/documents'
	},
	{
		title: 'Новости',
		subtitle: 'Читайте наш интересный блог',
		icon: <IcNews />,
		path: '/allNews'
	},
	{
		title: 'Вопросы',
		subtitle: '100+ вопросов',
		icon: <IcHomeQuest />,
		path: '/'
	}
];
