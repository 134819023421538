import { ReactComponent as IcHome } from '../../assets/images/icons/home.svg';
import { ReactComponent as IcHouses } from '../../assets/images/icons/houses.svg';
import { ReactComponent as IcHomeQuest } from '../../assets/images/icons/home_quest.svg';
import { ReactComponent as IcHomeStorage } from '../../assets/images/icons/home_storage.svg';
import { ReactComponent as IcHelpOutside } from '../../assets/images/icons/help_outline.svg';
import { ReactComponent as IcSuitcase } from '../../assets/images/icons/suitcase.svg';
import { ReactComponent as IcPaper } from '../../assets/images/icons/paper.svg';
import { ReactComponent as IcNews } from '../../assets/images/icons/news.svg';
import { ReactComponent as IcHomePhoto } from '../../assets/images/icons/home_photo.svg';

export const menuData = [
	{
		id: 'rent',
		title: 'Аренда',
		categories: [
			{
				title: 'Дома агенства',
				subtitle: 'Продажа коммерческих помещений',
				icon: <IcHome />,
				path: '/agencyHouses'
			},
			{
				title: 'Выкуп жилья',
				subtitle: 'Участникам государственных программ',
				icon: <IcHome />,
				path: '/homeBuyout'
			},
			{
				title: 'Аренда на площадке',
				subtitle: 'Актуальные объявления квартир',
				icon: <IcHouses />,
				path: '/'
			},
			{
				title: 'Вопросы',
				subtitle: '100+ вопросов',
				icon: <IcHomeQuest />,
				path: '/'
			}
		]
	},
	{
		id: 'sale',
		title: 'Продажа',
		categories: [
			{
				title: 'Коммерческая недвижимость',
				subtitle: 'Продажа коммерческих помещений',
				icon: <IcHome />,
				path: '/saleImmovable'
			},
			{
				title: 'Квартиры',
				subtitle: 'Участникам государственных программ',
				icon: <IcHome />,
				path: '/'
			},
			{
				title: 'Продажа на площадке',
				subtitle: 'Актуальные объявления квартирир',
				icon: <IcHouses />,
				path: '/'
			},
			{
				title: 'Вопросы',
				subtitle: '100+ вопросов',
				icon: <IcHomeQuest />,
				path: '/'
			}
		]
	},
	{
		id: 'mortgage',
		title: 'Ипотека',
		categories: [
			{
				title: 'Дальневосточная ипотека',
				subtitle: 'Льготная выдача ипотечных кредитов',
				icon: <IcHomeStorage />,
				path: '/easternMortgage'
			},
			{
				title: 'Шаг за шагом',
				subtitle: 'Делаем новое жильё ещё более доступным',
				icon: <IcHelpOutside />,
				path: '/stepByStep'
			}
		]
	},
	{
		id: 'aboutAgency',
		title: 'Об агенстве',
		categories: [
			{
				title: 'О нашей компании',
				subtitle: 'Адрес, реквзиты, корп. информация',
				icon: <IcSuitcase />,
				path: '/aboutCompany'
			},
			{
				title: 'Документы и Заявления',
				subtitle: 'Информация для заемщиков и другое',
				icon: <IcPaper />,
				path: '/documents'
			},
			{
				title: 'Новости',
				subtitle: 'Читайте наш интересный блог',
				icon: <IcNews />,
				path: '/allNews'
			},
			{
				title: 'Фотоотчеты',
				subtitle: 'Подверждаем слова - делом',
				icon: <IcHomePhoto />,
				path: '/allPhotoReports'
			}
		]
	}
];
