import Main from '../../containers/Main/Main';
import NewsContent from './components/NewsContent/NewsContent';
import LastNews from './components/LastNews/LastNews';

const News = () => {
	return (
		<Main>
			<NewsContent />
			<LastNews />
		</Main>
	);
};

export default News;
