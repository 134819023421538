import { FC } from 'react';
import styles from './FormHeading.module.scss';
export interface IFormHeading {
	heading: string;
	subHeading?: string;
	link?: string;
	linkLabel?: string;
}
const FormHeading: FC<IFormHeading> = ({
	heading,
	subHeading,
	link,
	linkLabel
}) => {
	return (
		<div className={styles.headingContainer}>
			<h1 className={styles.heading}>{heading}</h1>
			<div className={styles.subHeading}>
				<p>{subHeading}</p>
				<a href={link}>{linkLabel}</a>
			</div>
		</div>
	);
};

export default FormHeading;
