import styles from './RentAdvert.module.scss';

import Main from '../../containers/Main/Main';
import SectionWrapper from '../../containers/SectionWrapper/SectionWrapper';
import Heading from './components/Heading/Heading';
import Gallery from './components/Gallery/Gallery';
import Info from './components/Info/Info';
import Description from './components/Description/Description';
import AdditionalInfo from './components/AdditionalInfo/AdditionalInfo';
import Map from './components/Map/Map';
import Price from './components/Price/Price';

const RentAdvert = () => {
	return (
		<Main>
			<SectionWrapper>
				<div className={styles.container}>
					<div className={styles.aSide}>
						<div className={styles.headingContainer}>
							<Heading />
							<Gallery />
						</div>
						<Info />
						<Map />
						<Description />
						<AdditionalInfo />
					</div>
					<div className={styles.bSide}>
						<Price />
					</div>
				</div>
			</SectionWrapper>
		</Main>
	);
};

export default RentAdvert;
