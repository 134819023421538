import { FC, ReactNode } from 'react';
import styles from './Services.module.scss';

interface ServicesCardProps {
	text: string;
	path: string;
	icon: ReactNode;
}

const ServicesCard: FC<ServicesCardProps> = ({ text, path, icon }) => {
	return (
		<div className={styles.servicesCard}>
			<a href={path}></a>
			<p>{text}</p>
			<i className={styles.icon}>{icon}</i>
		</div>
	);
};

export default ServicesCard;
