import React, { FC } from 'react';
import styles from './Price.module.scss';
import classNames from 'classnames';
import FormButton from '../../../../ui/Form/components/FormButton/FormButton';

interface IPrice {
	className?: string;
}
const Price: FC<IPrice> = ({ className }) => {
	return (
		<div className={classNames(styles.container, className)}>
			<h1 className={styles.price}>20 000 ₽ / мес.</h1>

			<p>113 934 ₽ за м²</p>
			<p>
				<span>Возможна ипотека</span>
			</p>
			<div className={styles.address}>
				<p>Сахалинская область, Южно-Сахалинск, просп. Мира, 247А</p>
				<p>
					<span>ЖК Мичуринский </span>
				</p>
			</div>
			<FormButton className={styles.button}>Связаться</FormButton>
			<div className={styles.contacts}>
				<p>
					<span>Контактное лицо</span>
					<br /> Иван Иванов
				</p>
			</div>
		</div>
	);
};

export default Price;
