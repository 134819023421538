import SectionWrapper from '../../../../containers/SectionWrapper/SectionWrapper';
import SectionRow from '../../../../containers/SectionRow/SectionRow';
import SeeAllLink from '../../../../ui/SeeAllLink/SeeAllLink';
import NewsContainer from '../../../../containers/NewsContainer/NewsContainer';
import { newsSecondData } from './NewsSecond.data';
import { v4 as uuidv4 } from 'uuid';
import NewsCard from '../../../../components/NewsCard/NewsCard';

const NewsSecond = () => {
	return (
		<SectionWrapper pbNone={true}>
			<SectionRow>
				<h1>Наши интересные новости</h1>
				<SeeAllLink path={'/allNews'} mobileNone />
			</SectionRow>

			<NewsContainer>
				{newsSecondData.map(card => {
					return (
						<NewsCard
							key={uuidv4()}
							img={card.img}
							title={card.title}
							newsContent={card.newsContent}
							date={card.date}
							path={card.path}
						/>
					);
				})}
			</NewsContainer>

			<SeeAllLink path={'/allNews'} desktopNone />
		</SectionWrapper>
	);
};

export default NewsSecond;
