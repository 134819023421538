import { ReactComponent as IcHouses } from '../../../../assets/images/icons/houses.svg';
import { ReactComponent as IcHomeQuest } from '../../../../assets/images/icons/home_quest.svg';
import { ReactComponent as IcHomeStorage } from '../../../../assets/images/icons/home_storage.svg';
import { ReactComponent as IcHelp } from '../../../../assets/images/icons/help_outline.svg';

export const mortgageProgramData = [
	{
		icon: <IcHouses />,
		title: 'Ипотечный займ',
		subtitle: 'Список необходимых документов',
		path: '/'
	},
	{
		icon: <IcHomeQuest />,
		title: 'Индивидуальное жилищное строительство',
		subtitle: 'Правила выдачи и документы',
		path: '/'
	},
	{
		icon: <IcHomeQuest />,
		title: 'Дальневосточный гектар',
		subtitle: 'Правила выдачи и документы',
		path: '/'
	},
	{
		icon: <IcHomeStorage />,
		title: 'Дальневосточная ипотека',
		subtitle: 'Льготная выдача ипотечных кредитов',
		path: '/'
	},
	{
		icon: <IcHelp />,
		title: 'Шаг за шагом',
		subtitle: 'Делаем новое жильё ещё более доступным',
		path: '/'
	},
	{
		icon: <IcHomeQuest />,
		title: 'Есть вопрос?',
		subtitle: 'Задайте его прямо на сайте',
		path: '/'
	}
];
