import CategoryCard from '../../../../components/CategoryCard/CategoryCard';
import styles from './MortgageProgram.module.scss';
import SectionWrapper from '../../../../containers/SectionWrapper/SectionWrapper';
import { mortgageProgramData } from './MortgageProgram.data';
import { v4 as uuidv4 } from 'uuid';
import adImg from '../../../../assets/images/ad/ad2.jpg';
import AdBox from '../../../../components/AdBox/AdBox';

const MortgageProgram = () => {
	return (
		<SectionWrapper>
			<h1 className={'mb-4'}>Ипотечные программы</h1>

			<div className={styles.row}>
				{mortgageProgramData.map(card => {
					return (
						<CategoryCard
							className={styles.card}
							key={uuidv4()}
							icon={card.icon}
							title={card.title}
							subtitle={card.subtitle}
							path={card.path}
						/>
					);
				})}
			</div>

			<AdBox className={styles.ad} image={adImg} />
		</SectionWrapper>
	);
};

export default MortgageProgram;
