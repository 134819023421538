import styles from './MobileMenu.module.scss';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from 'react-accessible-accordion';
import { menuData } from '../../core/data/menu.data';
import { useAppDispatch, useAppSelector } from '../../core/hooks/storeHook';
import { setMenuMobileActive } from '../../core/store/slices/MenuSlice';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

const MobileMenu = () => {
	const { isMobileMenuActive } = useAppSelector(state => state.MenuSlice);
	const dispatch = useAppDispatch();

	const toggleMobileMenu = () => {
		document.body.classList.remove('lock');
		dispatch(setMenuMobileActive(false));
	};

	return (
		<div
			onClick={toggleMobileMenu}
			className={
				isMobileMenuActive
					? classNames(styles.mobileMenuWrapper, styles.visible)
					: styles.mobileMenuWrapper
			}
		>
			<div
				onClick={event => event.stopPropagation()}
				className={
					isMobileMenuActive
						? classNames(styles.mobileMenu, styles.visible)
						: styles.mobileMenu
				}
			>
				<Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
					{menuData.map((item, index) => {
						return (
							<AccordionItem key={index}>
								<AccordionItemHeading>
									<AccordionItemButton className={styles.accordionButton}>
										{item.title}
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel className={styles.panel}>
									{item.categories.map(card => {
										return (
											<a
												key={uuidv4()}
												href={card.path}
												className={styles.accordionElement}
											>
												<i className={styles.icon}>{card.icon}</i>
												<i>{card.title}</i>
											</a>
										);
									})}
								</AccordionItemPanel>
							</AccordionItem>
						);
					})}
				</Accordion>

				<a className={styles.link} href='/'>
					Вопросы
				</a>
				<a className={styles.link} href='/'>
					Контакты
				</a>
			</div>
		</div>
	);
};

export default MobileMenu;
