import styles from './Document.module.scss';
import Main from '../../containers/Main/Main';
import SectionWrapper from '../../containers/SectionWrapper/SectionWrapper';
import DocumentsRow from './components/DocumentsRow/DocumentsRow';
import DocumentsTitle from './components/DocumentsTitle/DocumentsTitle';
import DocumentsContent from './components/DocumentsContent/DocumentsContent';
import DownloadBtn from '../../ui/DownloadBtn/DownloadBtn';
import Wrapper from '../../containers/Wrapper/Wrapper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { categoryData } from '../AboutCompany/Category.data';
import CategoryCardPost from '../../components/CategoryCardPost/CategoryCardPost';

const Documents = () => {
	return (
		<Main>
			<SectionWrapper>
				<h2 className={'titleHeader'}>Документы и заявления</h2>

				<DocumentsRow>
					<DocumentsTitle>
						<h3>Информация для заемщиков</h3>
					</DocumentsTitle>

					<DocumentsContent>
						<div className={styles.infoItem}>
							<p>Досрочное гашение займа</p>
							<DownloadBtn />
						</div>

						<div className={styles.infoItem}>
							<p>Предоставление справки</p>
							<DownloadBtn />
						</div>

						<div className={styles.infoItem}>
							<p>Предоставление справки о закрытии займа</p>
							<DownloadBtn />
						</div>

						<div className={styles.infoItem}>
							<p>Предоставление справки для налоговой инспекции</p>
							<DownloadBtn />
						</div>

						<div className={styles.infoItem}>
							<p>Заявление свободной формы</p>
							<DownloadBtn />
						</div>

						<div className={styles.infoItem}>
							<p>Требование об отсрочке платежа</p>
							<DownloadBtn />
						</div>
					</DocumentsContent>
				</DocumentsRow>
			</SectionWrapper>

			<SectionWrapper>
				<DocumentsRow>
					<DocumentsTitle>
						<h3>Антикоррупционная политика</h3>
					</DocumentsTitle>

					<DocumentsContent>
						<p>
							Противодействие коррупции является особым видом публичной политики
							— антикоррупционной политикой, представляющей комплекс
							последовательных мер, осуществляемых на высшем государственном
							уровне и предполагающих вовлечение в их реализацию представителей
							власти, бизнеса и гражданского общества. Антикоррупционная
							политика — это деятельность, направленная на создание эффективной
							системы противодействия коррупции.
						</p>

						<DownloadBtn />
					</DocumentsContent>
				</DocumentsRow>
			</SectionWrapper>

			<SectionWrapper>
				<DocumentsRow>
					<DocumentsTitle>
						<h3>Кодекс этики и служебного поведения</h3>
					</DocumentsTitle>

					<DocumentsContent>
						<p>
							Кодекс этики и служебного поведения работников организации (далее
							– Кодекс этики), представляет собой совокупность общих принципов
							профессиональной этики и основных правил служебного поведения,
							которыми должны руководствоваться работники организации,
							независимо от занимаемой ими должности. Ознакомление с положениями
							Кодекса этики граждан, поступающих на работу в организации,
							производится в соответствии со статьей 68 Трудового кодекса
							Российской Федерации.
						</p>

						<DownloadBtn />
					</DocumentsContent>
				</DocumentsRow>
			</SectionWrapper>

			<SectionWrapper>
				<DocumentsRow>
					<DocumentsTitle>
						<h3>Урегулирование конфликта интересов</h3>
					</DocumentsTitle>

					<DocumentsContent>
						<p>
							Предотвращение или урегулирование конфликта интересов может
							состоять в изменении должностного или служебного положения
							государственного или муниципального служащего, являющегося
							стороной конфликта интересов, вплоть до его отстранения от
							исполнения должностных (служебных) обязанностей в установленном
							порядке, и (или) в отказе его от выгоды, явившейся причиной
							возникновения конфликтов интересов.
						</p>

						<DownloadBtn />
					</DocumentsContent>
				</DocumentsRow>
			</SectionWrapper>

			<SectionWrapper pbNone>
				<h1 className={'mb-6'}>Другие разделы</h1>

				<Wrapper>
					<Swiper
						spaceBetween={20}
						slidesPerView={'auto'}
						scrollbar={{ draggable: true }}
					>
						{categoryData.map((item, index) => {
							return (
								<SwiperSlide style={{ width: 'auto' }} key={index}>
									<CategoryCardPost
										icon={item.icon}
										title={item.title}
										subtitle={item.subtitle}
										path={item.path}
									/>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</Wrapper>
			</SectionWrapper>
		</Main>
	);
};

export default Documents;
