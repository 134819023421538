import { FC } from 'react';
import styles from './LastNewsCard.module.scss';

interface LastNewsCardProps {
	image: string;
	title: string;
	date: string;
	path: string;
}

const LastNewsCard: FC<LastNewsCardProps> = ({ image, title, date, path }) => {
	return (
		<div className={styles.card}>
			<a className={styles.link} href={path}></a>
			<div className={styles.imgWrapper}>
				<img src={image} alt='image' />
			</div>
			<div className={styles.body}>
				<p className={styles.date}>{date}</p>
				<h2 className={styles.title}>{title}</h2>
			</div>
		</div>
	);
};

export default LastNewsCard;
