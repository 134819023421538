import newsImg01 from '../../assets/images/newsImages/news1.png';
import newsImg02 from '../../assets/images/newsImages/news2.png';
import newsImg03 from '../../assets/images/newsImages/news3.png';
import newsImg04 from '../../assets/images/newsImages/news4.png';
import newsImg05 from '../../assets/images/newsImages/news5.png';

export const allNewsData = [
	{
		img: newsImg01,
		date: '05.04.2022',
		title:
			'Ход строительства многоквартирных жилых домов, предназначенных для сдачи в коммерческий найм',
		newsContent:
			'Строительство двух 48-квартирных жилых домов в г. Аниве, предназначенных для сдачи в коммерческий найм. Строительство многоквартирного жилого дома в г. Корсаков по ул. Артиллерийская, д. 48, предназначенного для сдачи в коммерческий найм. Строительство 70-квартирного жилого дома в г. Южно-Сахалинск, предназначенного для сдачи в коммерческий найм, по ул. Ленина 569а Строительство...',
		path: '/news'
	},
	{
		img: newsImg02,
		date: '08.02.2022',
		title:
			'Сдача 48-квартирного жилого дома в пгт. Шахтерске, предназначенного для сдачи в коммерческий найм',
		newsContent:
			'03 февраля 2022г. заселен арендный дом по ул. Октябрьская, д. 6 Дом построен по энергосберегающей технологии. Конструктив монолитный, сейсмоустойчивостью 9 баллов. Для отделки наружных стен использован вентилируемый фасад, облицованный керамогранитными плитами. На прилегающей территории арендного дома выполнено благоустройство, установлены малые архитектурные формы игровых...',
		path: '/news'
	},
	{
		img: newsImg03,
		date: '31.01.2022',
		title:
			'Ход строительства многоквартирных жилых домов, предназначенных для сдачи в коммерческий найм',
		newsContent:
			'Строительство двух 48-квартирных жилых домов в г. Аниве, предназначенных для сдачи в коммерческий найм. Строительство многоквартирного жилого дома в г. Корсаков по ул. Артиллерийская, д. 48, предназначенного для сдачи в коммерческий Строительство 70-квартирного жилого дома в г. Южно-Сахалинск, предназначенного для сдачи в коммерческий найм, по ул. Ленина 569а Строительство 24-',
		path: '/news'
	},
	{
		img: newsImg04,
		date: '31.01.2022',
		title:
			'Ход строительства многоквартирных жилых домов, предназначенных для сдачи в коммерческий найм',
		newsContent:
			'Строительство двух 48-квартирных жилых домов в г. Аниве, предназначенных для сдачи в коммерческий найм. Строительство многоквартирного жилого дома в г. Корсаков по ул. Артиллерийская, д. 48, предназначенного для сдачи в коммерческий Строительство 70-квартирного жилого дома в г. Южно-Сахалинск, предназначенного для сдачи в коммерческий найм, по ул. Ленина 569а Строительство 24-',
		path: '/news'
	},
	{
		img: newsImg05,
		date: '27.12.2021',
		title:
			'Ход строительства многоквартирных жилых домов, предназначенных для сдачи в коммерческий найм',
		newsContent:
			'Строительство 96-квартирного жилого дома в г. Корсаков по ул. Морская, д. 4, предназначенного для сдачи в коммерческий найм Строительство многоквартирного жилого дома в г. Корсаков по ул. Артиллерийская, д. 48, предназначенного для сдачи в коммерческий Строительство 70-квартирного жилого дома в г. Южно-Сахалинск, предназначенного для сдачи в коммерческий найм, по ул. Ленина 569а Строительство',
		path: '/news'
	}
];
