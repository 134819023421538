import { FC } from 'react';
import styles from './Image.module.scss';

interface ImageProps {
	src: string;
	alt: string;
	fluid?: boolean;
}

const Image: FC<ImageProps> = ({ src, alt, fluid }) => {
	return <img src={src} alt={alt} className={fluid && styles.fluid} />;
};

export default Image;
