import Main from '../../containers/Main/Main';
import SectionWrapper from '../../containers/SectionWrapper/SectionWrapper';
import Row from '../../components/Row/Row';
import CardsRow from '../../containers/CardsRow/CardsRow';
import {
	categoryData,
	offersData,
	questionsData,
	stepsData
} from './StepByStep.data';
import NumberCard from '../../components/NumberCard/NumberCards';
import MainAccordion from '../../components/MainAccordion/MainAccordion';
import Wrapper from '../../containers/Wrapper/Wrapper';
import SwiperPrev from '../../components/SwiperPrev/SwiperPrev';
import slideStep1 from '../../assets/images/any/slideStep1.jpg';
import slideStep2 from '../../assets/images/any/slideStep2.jpg';
import { useRef, useState } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperNext from '../../components/SwiperNext/SwiperNext';
import Image from '../../components/Image/Image';
import DownloadBtn from '../../ui/DownloadBtn/DownloadBtn';
import qrCode from '../../assets/images/any/qrCode.png';
import CategoryCardPost from '../../components/CategoryCardPost/CategoryCardPost';

const slides = [slideStep1, slideStep2, slideStep1];

const StepByStep = () => {
	const swiperRef = useRef<SwiperCore>();

	const [swiperNavigationAllowed, setSwiperNavigationAllowed] = useState<any>({
		prevButtonDisabled: true,
		nextButtonDisabled: false
	});

	return (
		<Main>
			<SectionWrapper pbNone>
				<h1 className={'titleHeader'}>Шаг за шагом</h1>

				<h2 className={'mb-6'}>Мы делаем новое жильё ещё более доступным</h2>

				<Row>
					<Row.Header>
						<h2>Наши предложения</h2>
					</Row.Header>
					<Row.Content>
						<CardsRow>
							{offersData.map((item, index) => {
								return (
									<NumberCard
										key={index}
										number={item.number}
										text={item.text}
									/>
								);
							})}
						</CardsRow>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper pbNone>
				<Row>
					<Row.Header>
						<h2>Вопросы</h2>
					</Row.Header>
					<Row.Content>
						<MainAccordion data={questionsData} />
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper pbNone>
				<Row>
					<Row.Header>
						<h2>Слайды</h2>
					</Row.Header>
					<Row.Content>
						<Wrapper>
							<Swiper
								spaceBetween={20}
								slidesPerView={'auto'}
								scrollbar={{ draggable: true }}
								onBeforeInit={(swiper: SwiperCore | undefined) => {
									swiperRef.current = swiper;
								}}
								onReachEnd={() =>
									setSwiperNavigationAllowed({
										...swiperNavigationAllowed,
										nextButtonDisabled: true
									})
								}
								onReachBeginning={() =>
									setSwiperNavigationAllowed({
										...swiperNavigationAllowed,
										prevButtonDisabled: true
									})
								}
								onSlideChange={() =>
									setSwiperNavigationAllowed({
										prevButtonDisabled: swiperRef?.current?.isBeginning,
										nextButtonDisabled: swiperRef?.current?.isEnd
									})
								}
							>
								{slides.map((item, index) => {
									return (
										<SwiperSlide style={{ width: 'auto' }} key={index}>
											<div style={{ width: '560px' }}>
												<Image src={item} alt={'слайд'} fluid />
											</div>
										</SwiperSlide>
									);
								})}
							</Swiper>

							<SwiperPrev
								onClick={() => swiperRef.current?.slidePrev()}
								disabled={swiperNavigationAllowed.prevButtonDisabled}
								position={50}
							/>
							<SwiperNext
								onClick={() => swiperRef.current?.slideNext()}
								disabled={swiperNavigationAllowed.nextButtonDisabled}
								position={50}
							/>
						</Wrapper>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper pbNone>
				<Row>
					<Row.Header>
						<h2>Шаги вместе</h2>
					</Row.Header>
					<Row.Content>
						<CardsRow>
							{stepsData.map((item, index) => {
								return (
									<NumberCard
										key={index}
										number={item.number}
										text={item.text}
									/>
								);
							})}
						</CardsRow>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper pbNone>
				<Row>
					<Row.Header>
						<h2>Документы</h2>
					</Row.Header>
					<Row.Content>
						<div className={'mb-5'}>
							<p className={'mb-2'}>Калькулятор Шаг за шагом в формате Excel</p>
							<DownloadBtn />
						</div>

						<div className={'mb-5'}>
							<p className={'mb-2'}>
								Заявка на участие в программе Шаг за шагом
							</p>
							<DownloadBtn />
						</div>

						<div className={'mb-5'}>
							<p className={'mb-2'}>Перечень необходимых документов</p>
							<DownloadBtn />
						</div>

						<div className={'mb-5'}>
							<p className={'mb-2'}>
								Согласие на обработку персональных данных
							</p>
							<DownloadBtn />
						</div>

						<div>
							<p className={'mb-2'}>Квартиры от застройщиков</p>
							<DownloadBtn />
						</div>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper>
				<Row>
					<Row.Header>
						<h2>Форма обратной связи</h2>
					</Row.Header>
					<Row.Content>
						<Image src={qrCode} alt={'qrCode'} />
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper pbNone>
				<h1 className={'mb-6'}>Другие разделы</h1>

				<Wrapper>
					<Swiper
						spaceBetween={20}
						slidesPerView={'auto'}
						scrollbar={{ draggable: true }}
						onBeforeInit={(swiper: SwiperCore | undefined) => {
							swiperRef.current = swiper;
						}}
						onReachEnd={() =>
							setSwiperNavigationAllowed({
								...swiperNavigationAllowed,
								nextButtonDisabled: true
							})
						}
						onReachBeginning={() =>
							setSwiperNavigationAllowed({
								...swiperNavigationAllowed,
								prevButtonDisabled: true
							})
						}
						onSlideChange={() =>
							setSwiperNavigationAllowed({
								prevButtonDisabled: swiperRef?.current?.isBeginning,
								nextButtonDisabled: swiperRef?.current?.isEnd
							})
						}
					>
						{categoryData.map((item, index) => {
							return (
								<SwiperSlide style={{ width: 'auto' }} key={index}>
									<CategoryCardPost
										icon={item.icon}
										title={item.title}
										subtitle={item.subtitle}
										path={item.path}
									/>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</Wrapper>
			</SectionWrapper>
		</Main>
	);
};

export default StepByStep;
