import { FC, ReactNode } from 'react';
import styles from './SectionRow.module.scss';

interface SectionRowProps {
	children: ReactNode;
}

const SectionRow: FC<SectionRowProps> = ({ children }) => {
	return <div className={styles.row}>{children}</div>;
};

export default SectionRow;
