import Form from '../../../ui/Form/Form';
import Main from '../../../containers/Main/Main';
import styles from './AskQuestion.module.scss';
import TextEditor from '../../../ui/Form/components/TextEditor/TextEditor';
import { TextEditorProvider } from '../../../core/providers/TextEditorProvider';
const AskQuestion = () => {
	return (
		<TextEditorProvider>
			<Main>
				<Form>
					<Form.Heading heading={'Задать вопрос'} />
					<Form.Input
						label={'Заголовок'}
						type={'text'}
						description={'Вопрос вкратце'}
					/>
					<TextEditor
						onChange={() => null}
						label={'Описание'}
						value={''}
						placeholder={'Подробнее описание вопроса'}
					/>
					<Form.Input label={'Ваше имя'} type={'text'} />
					<Form.Button>Отправить</Form.Button>
					<div className={styles.privacyPolicyAgreement}>
						<p>
							Нажимая на кнопку "Отправить", вы соглашаетесь на <br />
							<a href={'/'} className={styles.privacyPolicyLink}>
								обработку персональных данных
							</a>
						</p>
					</div>
				</Form>
			</Main>
		</TextEditorProvider>
	);
};

export default AskQuestion;
