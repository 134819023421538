import image1 from '../../../../assets/images/newsImages/last/last1.png';
import image2 from '../../../../assets/images/newsImages/last/last2.png';
import image3 from '../../../../assets/images/newsImages/last/last3.png';
import image4 from '../../../../assets/images/newsImages/last/last4.png';

export const lastNewsData = [
	{
		title:
			'Актуальные вопросы и ответы на них в выпуске Россия-1 “Сахалин-Курилы”',
		date: '31.01.2023',
		image: image1,
		path: '/'
	},
	{
		title:
			'51 летняя жительница Южно-Сахалинска переехала с окраины города в центр благодаря новой жилищной программе',
		date: '02.02.2023',
		image: image2,
		path: '/'
	},
	{
		title:
			'Актуальные вопросы и ответы на них в выпуске Россия-1 “Сахалин-Курилы”',
		date: '31.01.2023',
		image: image3,
		path: '/'
	},
	{
		title:
			'Актуальные вопросы и ответы на них в выпуске Россия-1 “Сахалин-Курилы”',
		date: '31.01.2023',
		image: image4,
		path: '/'
	},
	{
		title:
			'Актуальные вопросы и ответы на них в выпуске Россия-1 “Сахалин-Курилы”',
		date: '31.01.2023',
		image: image1,
		path: '/'
	},
	{
		title:
			'51 летняя жительница Южно-Сахалинска переехала с окраины города в центр благодаря новой жилищной программе',
		date: '02.02.2023',
		image: image2,
		path: '/'
	},
	{
		title:
			'Актуальные вопросы и ответы на них в выпуске Россия-1 “Сахалин-Курилы”',
		date: '31.01.2023',
		image: image3,
		path: '/'
	},
	{
		title:
			'Актуальные вопросы и ответы на них в выпуске Россия-1 “Сахалин-Курилы”',
		date: '31.01.2023',
		image: image4,
		path: '/'
	}
];
