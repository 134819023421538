import { FC, ReactNode, useEffect } from 'react';
import styles from './MainWrapper.module.scss';
import classNames from 'classnames';

interface MainWrapperProps {
	children: ReactNode;
}

const MainWrapper: FC<MainWrapperProps> = ({ children }) => {
	return <div className={classNames(styles.mainWrapper)}>{children}</div>;
};

export default MainWrapper;
