import { FC, ReactNode } from 'react';
import styles from './NewsContainer.module.scss';
import classNames from 'classnames';

interface NewsContainerProps {
	children: ReactNode;
	className?: string;
}

const NewsContainer: FC<NewsContainerProps> = ({ children, className }) => {
	return (
		<div className={classNames(styles.container, className)}>{children}</div>
	);
};

export default NewsContainer;
