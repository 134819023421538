import Main from '../../containers/Main/Main';
import SectionWrapper from '../../containers/SectionWrapper/SectionWrapper';
import { allNewsData } from '../../core/data/allNews.data';
import NewsCard from '../../components/NewsCard/NewsCard';
import Pagination from '../../ui/Pagination/Pagination';
import { useState } from 'react';
import Wrapper from '../../containers/Wrapper/Wrapper';

const AllNews = () => {
	const [num, setNum] = useState<number>(1);
	const pages = [1, 2, 3];

	return (
		<Main>
			<SectionWrapper ptNone pbNone>
				<h1 className={'mb-4'}>Новости</h1>

				<Wrapper>
					{allNewsData.map((card, index) => {
						return (
							<NewsCard
								key={index}
								img={card.img}
								title={card.title}
								newsContent={card.newsContent}
								date={card.date}
								path={card.path}
							/>
						);
					})}
				</Wrapper>

				<Pagination className={'mt-6'}>
					{pages.map((item, index) => {
						return (
							<Pagination.Item
								key={index}
								onClick={() => setNum(item)}
								active={item === num}
							>
								{item}
							</Pagination.Item>
						);
					})}
					<Pagination.Prev />
					<Pagination.Next />
				</Pagination>
			</SectionWrapper>
		</Main>
	);
};

export default AllNews;
