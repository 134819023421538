import Main from '../../containers/Main/Main';
import SectionWrapper from '../../containers/SectionWrapper/SectionWrapper';
import Row from '../../components/Row/Row';
import NumberCard from '../../components/NumberCard/NumberCards';
import CardsRow from '../../containers/CardsRow/CardsRow';
import { homeBuyoutData } from './HomeBuyout.data';
import map from '../../assets/images/map/map.png';
import Image from '../../components/Image/Image';
import Phone from '../../ui/Phone/Phone';
import { Swiper, SwiperSlide } from 'swiper/react';
import CategoryCardPost from '../../components/CategoryCardPost/CategoryCardPost';
import Wrapper from '../../containers/Wrapper/Wrapper';
import { categoryData } from './Category.data';

const HomeBuyout = () => {
	return (
		<Main>
			<SectionWrapper pbNone>
				<h1 className={'titleHeader'}>Выкуп арендного жилья</h1>

				<h2 className={'mb-6'}>Когда наниматель может выкупить квартиру</h2>

				<Row>
					<Row.Header>
						<h2>Условия</h2>
					</Row.Header>
					<Row.Content>
						<div className={'mb-6'}>
							<p className={'mb-2'}>
								<span>
									Впервые договор найма заключен не менее 6 месяцев назад
								</span>
							</p>
							<p>
								У вас нет неисполненных обязательств (долгов) по договору найма
								с Агенством и коммунальным платежам
							</p>
						</div>

						<div className={'mb-6'}>
							<p className={'mb-2'}>
								<span>
									До истечения 6 месяцев найма, если вы используете ипотечный
									кредит по программе:
								</span>
							</p>
							<p>
								Дальневосточная ипотека
								<br />
								Ипотека с государственной поддержкой
								<br />
								Семейная ипотека
								<br />
								Сельская ипотека
								<br />
								Ипотека для специалистов IT
								<br />
							</p>
						</div>

						<div>
							<p className={'mb-2'}>
								<span>
									Используете меры господдержки на улучшение жилищных условий
								</span>
							</p>
							<p className={'mb-2'}>Сертификат на материнский капитал</p>
							<p>
								Уведомление участника мероприятия по представлению социальных
								выплат
							</p>
						</div>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper>
				<Row>
					<Row.Header>
						<h2>Порядок выкупа</h2>
					</Row.Header>
					<Row.Content>
						<CardsRow>
							{homeBuyoutData.map((item, index) => {
								return (
									<NumberCard
										key={index}
										number={item.number}
										text={item.text}
										helpText={item.helpText}
									/>
								);
							})}
						</CardsRow>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper>
				<h1 className={'mb-6'}>Офис продаж</h1>

				<Row>
					<Row.Header>
						<h2>Место нахождения</h2>
						<p>
							Сахалинская область, г. Южно-Сахалинск,
							<br />
							ул. Дзержинского, д. 21 оф. 214
						</p>
						<h2>Номер телефона</h2>
						<Phone phone={'8 (4242) 312-531'} />
					</Row.Header>
					<Row.Content>
						<Image src={map} alt={'карта'} fluid />
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper pbNone>
				<h1 className={'mb-6'}>Другие разделы</h1>

				<Wrapper>
					<Swiper
						spaceBetween={20}
						slidesPerView={'auto'}
						scrollbar={{ draggable: true }}
					>
						{categoryData.map((item, index) => {
							return (
								<SwiperSlide style={{ width: 'auto' }} key={index}>
									<CategoryCardPost
										icon={item.icon}
										title={item.title}
										subtitle={item.subtitle}
										path={item.path}
									/>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</Wrapper>
			</SectionWrapper>
		</Main>
	);
};

export default HomeBuyout;
