import Select from 'react-select';
import styles from './SearchSelect.module.scss';

const SearchSelect = ({ options }) => {
	const customStyles = {
		option: (defaultStyles, state) => ({
			...defaultStyles,
			color: state.isSelected ? '#fff' : '#1E1E1E',
			backgroundColor: state.isSelected && '#5D6963',
			borderRadius: '5px',
			'&:hover': {
				backgroundColor: !state.isSelected && '#F2F2F2'
			},
			marginBottom: '4px',
			'&:last-child': {
				margin: 0
			}
		}),

		control: defaultStyles => ({
			...defaultStyles,
			backgroundColor: 'transparent',
			padding: '0px',
			border: 'none',
			boxShadow: 'none'
		}),

		indicatorSeparator: defaultStyles => ({
			...defaultStyles,
			display: 'none'
		}),

		menu: defaultStyles => ({
			...defaultStyles,
			padding: '4px'
		}),

		dropdownIndicator: (defaultStyles, state) => ({
			...defaultStyles,
			transition: 'all .2s ease',
			transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
		})
	};

	return (
		<Select
			defaultValue={options[0]}
			options={options}
			className={styles.searchSelectContainer}
			styles={customStyles}
			isSearchable={false}
		/>
	);
};

export default SearchSelect;
