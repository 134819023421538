import React, { FC, useState } from 'react';
import Select from 'react-select';
import generalInputStyles from '../Input/Input.module.scss';
import generateTranslit from '../../../../core/utils/generateTranslit';
export interface IDropDown {
	options: Array<object>;
	label: string;
	description?: string;
	placeholder?: string;
	required?: boolean;
	checkbox?: string;
}

const customStyles = {
	control: (provided, state) => ({
		...provided,
		boxShadow: state.isFocused ? '0 0 0 1px #1E1E1E' : '0 0 0 1px #B4B4B9',
		borderRadius: '8px',
		padding: '10px 16px',
		'&:hover': {
			borderColor: '#d6d6d6'
		},
		border: 'none'
	}),
	indicatorSeparator: () => ({
		display: 'none'
	}),
	menu: (provided, state) => ({
		...provided,
		boxShadow: '0 0 0 1px #B4B4B9',
		width: '96%'
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		marginRight: 0,
		transition: 'transform .2s',
		transform: state.isFocused ? 'rotateZ(180deg)' : 'rotateZ(0deg)',
		color: state.isFocused ? '#1E1E1E' : '#B4B4B9'
	}),
	option: (provided, state) => ({
		...provided,
		height: '100%',
		padding: '12px 24px',
		width: '98%',
		margin: 4,
		borderRadius: 8,
		'&:active': {
			background: '#F2F2F2'
		}
	})
};

const Dropdown: FC<IDropDown> = ({
	options,
	label,
	description,
	required,
	checkbox,
	placeholder
}) => {
	const [selectedOption, setSelectedOption] = useState(null);

	const handleSelectChange = selectedOption => {
		setSelectedOption(selectedOption);
	};

	return (
		<div className={generalInputStyles.container}>
			<label htmlFor={generateTranslit(label)}>
				<p>
					{label}
					{required && <span className={generalInputStyles.required}>*</span>}
				</p>
			</label>
			<Select
				id={generateTranslit(label)}
				placeholder={placeholder ? placeholder : 'Не указано'}
				options={options}
				value={selectedOption}
				onChange={handleSelectChange}
				styles={customStyles}
				theme={theme => ({
					...theme,
					borderRadius: 8,
					colors: {
						...theme.colors,
						primary25: ' #F2F2F2',
						primary: '#5D6963',
						boxShadowColor: 'blue'
					}
				})}
			/>

			{description && (
				<p className={generalInputStyles.description}>{description}</p>
			)}
			{checkbox && (
				<div>
					<input
						id={generateTranslit(checkbox)}
						type={'checkbox'}
						name={checkbox}
						className={generalInputStyles.checkBox}
						value={checkbox}
					/>
					<label htmlFor={generateTranslit(checkbox)}>{checkbox}</label>
				</div>
			)}
		</div>
	);
};

export default Dropdown;
