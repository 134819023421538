import { RouterProvider } from 'react-router-dom';
import { router } from './core/utils/router/router';
import MainWrapper from './containers/MainWrapper/MainWrapper';
import Footer from './layouts/Footer/Footer';
import Navbar from './layouts/Navbar/Navbar';
import Menu from './layouts/Menu/Menu';
import MobileMenu from './layouts/MobileMenu/MobileMenu';

const App = () => {
	return (
		<MainWrapper>
			<Navbar />
			<Menu />
			<MobileMenu />
			<RouterProvider router={router} />
			<Footer />
		</MainWrapper>
	);
};

export default App;
