import Main from '../../containers/Main/Main';
import Form from '../../ui/Form/Form';

const Auth = () => {
	return (
		<Main>
			<Form>
				<Form.Heading
					heading={'Авторизация'}
					subHeading={'Впервые тут?'}
					linkLabel={'Создать аккаунт'}
					link={'/register'}
				/>
				<Form.Input type={'text'} label={'Почта'} />
				<Form.Input type={'password'} label={'Пароль'} />
				<Form.Button>Войти в аккаунт</Form.Button>
			</Form>
		</Main>
	);
};

export default Auth;
