import styles from '../AdditionalInfo/AdditionalInfo.module.scss';

const Info = () => {
	return (
		<div>
			<h1 className={styles.heading}>Информация об объекте</h1>
			<div className={styles.container}>
				<div>
					<p>
						<span>Общая площадь:</span> 44 м²
					</p>
					<p>
						<span>Жилая площадь:</span> 20 м²
					</p>
					<p>
						<span>Площадь кухни:</span> 8.7 м²
					</p>
					<p>
						<span>Планировка:</span> Студия
					</p>
				</div>
				<div>
					<p>
						<span>Этаж:</span> 3 из 5
					</p>
					<p>
						<span>Материал стен:</span> Бетон
					</p>
					<p>
						<span>Высота потолков:</span> 2.6 м
					</p>
					<p>
						<span>Год постройки:</span> 2017
					</p>
				</div>
			</div>
		</div>
	);
};

export default Info;
