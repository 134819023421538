import styles from './Offer.module.scss';
import SectionWrapper from '../../../../containers/SectionWrapper/SectionWrapper';
import { offerData } from './Offer.data';
import OfferCard from './OfferCard';
import { v4 as uuidv4 } from 'uuid';

const Offer = () => {
	return (
		<SectionWrapper>
			<div className={styles.row}>
				{offerData.map(item => {
					return (
						<OfferCard key={uuidv4()} title={item.title} params={item.params} />
					);
				})}
			</div>
		</SectionWrapper>
	);
};

export default Offer;
