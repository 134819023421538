import styles from './Description.module.scss';

const Description = () => {
	return (
		<div className={styles.container}>
			<h1>Описание</h1>
			<p>
				2х комнатная квартира, теплая, не угловая, в спокойном районе. Готова к
				проживанию. Один собственник, без долгов и обременений, больше пяти лет
				в собственности. Полная стоимость в договоре при продаже. Приобреталась
				по договору купли-продажи. Все документы готовы. С квартире никто не
				зарегистрирован.
				<br />
				<br />
				Расположение: Дом закрыт от проезжих дорог (Мира, Пуркаево,
				Комсомольская). Все в шаговой доступности.
				<br />
				<br />
				Рядом: Детские сады, школы, поликлиники, магазин Столичный, пекарни.
				Отделение Почты, автобусные остановки, ТЦ Столица, ТЦ Рай, ТЦ Панорама,
				ТЦ Плаза, магазин Эконом. Все рядом.
			</p>
		</div>
	);
};

export default Description;
