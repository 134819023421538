import { FC, ReactNode } from 'react';
import styles from './DocumentsRow.module.scss';

interface DocumentRowProps {
	children: ReactNode;
}

const DocumentsRow: FC<DocumentRowProps> = ({ children }) => {
	return <div className={styles.row}>{children}</div>;
};

export default DocumentsRow;
