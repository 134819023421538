import { FC, ReactNode } from 'react';
import styles from './AccentButton.module.scss';
import classNames from 'classnames';

interface AccentButtonProps {
	path: string;
	children: ReactNode;
	className?: string;
}

const AccentButton: FC<AccentButtonProps> = ({ path, children, className }) => {
	return (
		<a className={classNames(styles.accentButton, className)} href={path}>
			{children}
		</a>
	);
};

export default AccentButton;
