import styles from './Navbar.module.scss';
import { useAppDispatch, useAppSelector } from '../../core/hooks/storeHook';
import { setMenuMobileActive } from '../../core/store/slices/MenuSlice';
import classNames from 'classnames';

const Burger = () => {
	const { isMobileMenuActive } = useAppSelector(state => state.MenuSlice);
	const dispatch = useAppDispatch();

	const openMenu = () => {
		dispatch(setMenuMobileActive(!isMobileMenuActive));
		document.body.classList.toggle('lock');
	};

	return (
		<div
			onClick={openMenu}
			className={classNames(styles.burger, isMobileMenuActive && styles.active)}
			role={'button'}
		>
			<span></span>
			<span></span>
			<span></span>
		</div>
	);
};

export default Burger;
