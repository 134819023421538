import styles from './SaleImmovable.module.scss';
import Main from '../../containers/Main/Main';
import SectionWrapper from '../../containers/SectionWrapper/SectionWrapper';
import Row from '../../components/Row/Row';
import { useRef, useState } from 'react';
import SwiperCore from 'swiper';
import { SwiperSlide, Swiper } from 'swiper/react';
import SwiperPrev from '../../components/SwiperPrev/SwiperPrev';
import SwiperNext from '../../components/SwiperNext/SwiperNext';
import Wrapper from '../../containers/Wrapper/Wrapper';
import room1 from '../../assets/images/saleImmovable/room1.png';
import room2 from '../../assets/images/saleImmovable/room2.png';
import room3 from '../../assets/images/saleImmovable/room3.png';
import roomOutline from '../../assets/images/saleImmovable/outline.png';
import Image from '../../components/Image/Image';
import DownloadBtn from '../../ui/DownloadBtn/DownloadBtn';
import Phone from '../../ui/Phone/Phone';
import CategoryCardPost from '../../components/CategoryCardPost/CategoryCardPost';
import { saleImmovableData } from './SaleImmovable.data';

const photosRooms = [room1, room2, room3, room1];

const SaleImmovable = () => {
	const swiperRef = useRef<SwiperCore>();

	const [swiperNavigationAllowed, setSwiperNavigationAllowed] = useState<any>({
		prevButtonDisabled: true,
		nextButtonDisabled: false
	});

	return (
		<Main>
			<SectionWrapper pbNone>
				<h1 className={'titleHeader'}>Продажа коммерческой недвижимости</h1>

				<h2 className={'mb-6'}>Нежилые коммерческие помещения</h2>

				<Row>
					<Row.Header>
						<h2>Адрес</h2>
					</Row.Header>
					<Row.Content>
						<p className={'mb-md-2'}>
							<span>Южно-Сахалинск, ул. Дзержинского, 40</span>
						</p>
					</Row.Content>
				</Row>
				<Row>
					<Row.Header>
						<h2>Состояние</h2>
					</Row.Header>
					<Row.Content>
						<p className={'mb-md-2'}>
							<span>Требуется ремонт</span>
						</p>
					</Row.Content>
				</Row>
				<Row>
					<Row.Header>
						<h2>Стоимость реализации</h2>
					</Row.Header>
					<Row.Content>
						<p>
							<span>39 000 000 ₽, в т.ч. НДС.</span>
						</p>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper pbNone>
				<Row>
					<Row.Header>
						<h2>Общее описание</h2>
					</Row.Header>
					<Row.Content>
						<p>
							<span>
								Объект подключен ко всем системам централизованных коммуникаций.
								Большая проходимость комплекса и большая парковочная зона, в
								здании располагается лифт.
								<br />
								<br />
								Находится в непосредственной близости от Правительства
								Сахалинской области, ТЦ Дом Торговли, ТЦ Славянский базар,
								супермаркетов, автобусных остановок, различных государственных
								учреждений. В здании расположена аптека.
							</span>
						</p>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper>
				<Row>
					<Row.Header>
						<h2>Фотографии</h2>
					</Row.Header>
					<Row.Content>
						<Wrapper>
							<Swiper
								spaceBetween={20}
								slidesPerView={'auto'}
								scrollbar={{ draggable: true }}
								onBeforeInit={(swiper: SwiperCore | undefined) => {
									swiperRef.current = swiper;
								}}
								onReachEnd={() =>
									setSwiperNavigationAllowed({
										...swiperNavigationAllowed,
										nextButtonDisabled: true
									})
								}
								onReachBeginning={() =>
									setSwiperNavigationAllowed({
										...swiperNavigationAllowed,
										prevButtonDisabled: true
									})
								}
								onSlideChange={() =>
									setSwiperNavigationAllowed({
										prevButtonDisabled: swiperRef?.current?.isBeginning,
										nextButtonDisabled: swiperRef?.current?.isEnd
									})
								}
							>
								{photosRooms.map((item, index) => {
									return (
										<SwiperSlide style={{ width: 'auto' }} key={index}>
											<div className={styles.photoRoom}>
												<img src={item} alt='img' />
											</div>
										</SwiperSlide>
									);
								})}
							</Swiper>

							<SwiperPrev
								onClick={() => swiperRef.current?.slidePrev()}
								disabled={swiperNavigationAllowed.prevButtonDisabled}
								className={styles.pos}
							/>
							<SwiperNext
								onClick={() => swiperRef.current?.slideNext()}
								disabled={swiperNavigationAllowed.nextButtonDisabled}
								className={styles.pos}
							/>
						</Wrapper>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper pbNone>
				<h1 className={'mb-6'}>Помещения в строящемся ЖК «Горизонт»</h1>
				<Row>
					<Row.Header>
						<h2>Адрес</h2>
					</Row.Header>
					<Row.Content>
						<p className={'mb-md-2'}>
							<span>ЖК «Горизонт» </span>
						</p>
					</Row.Content>
				</Row>
				<Row>
					<Row.Header>
						<h2>Состояние</h2>
					</Row.Header>
					<Row.Content>
						<p className={'mb-md-2'}>
							<span>На этапе строительства</span>
						</p>
					</Row.Content>
				</Row>
				<Row>
					<Row.Header>
						<h2>Стоимость бронирования</h2>
					</Row.Header>
					<Row.Content>
						<p>
							<span>100 000 ₽*</span>
						</p>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper>
				<Row>
					<Row.Header>
						<h2>Общее описание</h2>
					</Row.Header>
					<Row.Content>
						<p>
							Юридическим лицам и индивидуальным предпринимателям предлагается
							забронировать коммерческие помещения в строящемся ЖК «Горизонт».
							<br />
							<br />
							Перечень предлагаемых к бронированию нежилых помещений – согласно
							Схеме расположения коммерческих помещений жилых домов по секциям.
							<br />
							<br />
							Коммерческие помещения – без отделки. Обращаем внимание на то, что
							в непосредственной близости с жилыми домами будут расположены
							детский сад и школа. Расстояние – согласно Схеме расстояний.
							<br />
							<br />* Стоимость бронирования – 100 000 (сто тысяч) рублей,
							которые зачитываются в стоимость помещения в случае заключения
							договора купли-продажи. В случае отказа от заключения договора
							купли-продажи стоимость бронирования возврату не подлежит.
							<br />
							<br />
							Заключение соглашений о бронировании осуществляется с
							заинтересованными предпринимателями, обратившимися в Агентство,
							подтвердившими свою правоспособность и предоставившими данные об
							источнике оплаты не менее чем 50% от стоимости объекта
							недвижимости. Обработка заявок на бронирование осуществляется в
							той же хронологической последовательности, в которой в Агентство
							поступят заявки на бронирование.
							<br />
							<br />
							Заявки можно направлять по электронной почте sakhipoteka@mail.ru
							(с обязательным предоставлением оригинальных документов в течение
							5 (пяти) рабочих дней) или нарочно по адресу: г. Южно-Сахалинск,
							ул. Дзержинского д.21, оф.219. В случае предоставления нескольких
							заявок – дублирование комплекта документов-приложений не
							требуется. Дата окончания приема заявок – 31.03.2023.
							<br />
							<br />
							Ориентировочная дата завершения строительства и передачи помещений
							– июнь-октябрь 2023 года. Передача помещения осуществляется после
							100% оплаты стоимости помещения. 100% оплаты должно поступить на
							счет Агентства не позднее 30 дней с даты получения извещения о
							готовности объекта к передаче.
						</p>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper pbNone>
				<Row>
					<Row.Header>
						<h2>Фотографии</h2>
					</Row.Header>
					<Row.Content>
						<Image src={roomOutline} alt={'план'} fluid />
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper>
				<Row>
					<Row.Header>
						<h2>Документы</h2>
					</Row.Header>
					<Row.Content>
						<div className={'mb-5'}>
							<p className={'mb-1'}>Заявка на бронирование</p>
							<DownloadBtn />
						</div>

						<div className={'mb-5'}>
							<p className={'mb-1'}>
								Схема расположения коммерческих помещений
							</p>
							<DownloadBtn />
						</div>

						<div className={'mb-5'}>
							<p className={'mb-1'}>Схема расстояний</p>
							<DownloadBtn />
						</div>

						<p className={'mb-1'}>План мкр Горизонт</p>
						<DownloadBtn />
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper>
				<Row>
					<Row.Header>
						<h1 className={'mb-md-6'}>Контакты</h1>
					</Row.Header>
					<Row.Content>
						<div className={'mb-5'}>
							<h2 className={'mb-1'}>Номер телефона</h2>
							<Phone phone={'8 (4242) 312-532'} />
							<Phone phone={'8 (4242) 312-531'} />
						</div>

						<div className={'mb-5'}>
							<h2 className={'mb-1'}>Факс</h2>
							<Phone phone={'8 (4242) 312-533'} />
						</div>

						<h2 className={'mb-2'}>Наш офис</h2>
						<p>
							Сахалинская область, г. Южно-Сахалинск,
							<br /> ул. Дзержинского, д. 21 оф. 219
						</p>
					</Row.Content>
				</Row>
			</SectionWrapper>

			<SectionWrapper pbNone>
				<h1 className={'mb-6'}>Другие разделы</h1>

				<Swiper
					spaceBetween={20}
					slidesPerView={'auto'}
					scrollbar={{ draggable: true }}
				>
					{saleImmovableData.map((item, index) => {
						return (
							<SwiperSlide style={{ width: 'auto' }} key={index}>
								<CategoryCardPost
									icon={item.icon}
									title={item.title}
									subtitle={item.subtitle}
									path={item.path}
								/>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</SectionWrapper>
		</Main>
	);
};

export default SaleImmovable;
