import React from 'react';
import styles from './Footer.module.scss';
import Container from '../../containers/Container/Container';
import LogoLight from '../../ui/LogoLight/LogoLight';
import { navigationData } from '../../core/data/navigation.data';
import { v4 as uuidv4 } from 'uuid';
const Footer = () => {
	return (
		<footer className={styles.footer}>
			<Container className={styles.footerContainer}>
				<div className={styles.footerNav}>
					<ul>
						{navigationData.map(li => {
							return (
								<li key={uuidv4()}>
									<a href={li.path}>{li.title}</a>
								</li>
							);
						})}
					</ul>
					<a href={'tel:+7 (4242) 312-532'} className={styles.phoneNumber}>
						8 (4242) 312-532
					</a>
				</div>
				<div className={styles.footerInfo}>
					<LogoLight />
					<p>
						© 2023, АО «СиА»
						<br />
						Сахалинская обл., г. Южно-Сахалинск, ул. Дзержинского, д. 21 оф. 219
					</p>
				</div>
			</Container>
		</footer>
	);
};

export default Footer;
