import Main from '../../containers/Main/Main';
import Question from './Components/Question/Question';
import { questionsData } from './Questions.data';

const Questions = () => {
	return (
		<Main>
			<Question questions={questionsData} />
		</Main>
	);
};

export default Questions;
