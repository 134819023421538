import React, { FC, useState } from 'react';
import styles from './FormToggleButton.module.scss';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
export interface IToggleInput {
	options: Array<string>;
	label: string;
	clickFunction?: () => void;
}

const FormToggleButton: FC<IToggleInput> = ({
	options,
	label,
	clickFunction
}) => {
	const [activeButtonIndex, setActiveButtonIndex] = useState<number>(0);

	const clickHandler = (buttonIndex: number) => {
		setActiveButtonIndex(buttonIndex);
	};

	return (
		<div className={styles.container}>
			<p>{label}</p>
			<div className={styles.switches}>
				{options.map((option, index) => (
					<button
						key={uuidv4()}
						disabled={activeButtonIndex === index}
						className={classNames(styles.switchButton, {
							[styles.active]: activeButtonIndex === index
						})}
						onClick={() => {
							clickHandler(index);
							clickFunction && clickFunction();
						}}
					>
						{option}
					</button>
				))}
			</div>
		</div>
	);
};

export default FormToggleButton;
