import flat01 from '../../../../assets/images/flatImages/flat1.png';
import flat02 from '../../../../assets/images/flatImages/flat2.png';
import flat03 from '../../../../assets/images/flatImages/flat3.png';

export const rentalAdsData = [
	{
		image: flat01,
		price: '15 000',
		address:
			'Сахалин, Южно-Сахалинск, р-н Пролетарский, просп. Николая Корыткова, 28',
		typeFlat: '1-к. квартира',
		squareFlat: '43 м²',
		floorFlat: '5/12 эт.',
		path: '/RentAdvert'
	},
	{
		image: flat02,
		price: '15 000',
		address:
			'Сахалин, Южно-Сахалинск, р-н Пролетарский, просп. Николая Корыткова, 28',
		typeFlat: '1-к. квартира',
		squareFlat: '43 м²',
		floorFlat: '5/12 эт.',
		path: '/RentAdvert'
	},
	{
		image: flat03,
		price: '3 100 000',
		address:
			'Сахалин, Южно-Сахалинск, р-н Пролетарский, просп. Николая Корыткова, 28',
		typeFlat: '1-к. квартира',
		squareFlat: '43 м²',
		floorFlat: '5/12 эт.',
		path: '/RentAdvert'
	},
	{
		image: flat01,
		price: '15 000',
		address:
			'Сахалин, Южно-Сахалинск, р-н Пролетарский, просп. Николая Корыткова, 28',
		typeFlat: '1-к. квартира',
		squareFlat: '43 м²',
		floorFlat: '5/12 эт.',
		path: '/RentAdvert'
	},
	{
		image: flat02,
		price: '15 000',
		address:
			'Сахалин, Южно-Сахалинск, р-н Пролетарский, просп. Николая Корыткова, 28',
		typeFlat: '1-к. квартира',
		squareFlat: '43 м²',
		floorFlat: '5/12 эт.',
		path: '/RentAdvert'
	}
];
