import Main from '../../containers/Main/Main';
import SearchBox from './components/SearchBox/SearchBox';
import Services from './components/Services/Services';
import RentalAds from './components/RentalAds/RentalAds';
import Ad from './components/Ad/Ad';
import MortgageProgram from './components/MortgageProgram/MortgageProgram';
import PurchaseAds from './components/PurchaseAds/PurchaseAds';
import News from './components/News/News';
import Offer from './components/Offer/Offer';
import NewsSecond from './components/NewsSecond/NewsSecond';

const Home = () => {
	return (
		<Main>
			<SearchBox />
			<Services />
			<RentalAds />
			<Ad />
			<MortgageProgram />
			<PurchaseAds />
			<News />
			<Offer />
			<NewsSecond />
		</Main>
	);
};

export default Home;
