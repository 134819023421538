import SectionWrapper from '../../../../containers/SectionWrapper/SectionWrapper';
import SectionRow from '../../../../containers/SectionRow/SectionRow';
import SeeAllLink from '../../../../ui/SeeAllLink/SeeAllLink';
import FlatSwiper from '../../../../components/FlatSwiper/FlatSwiper';
import { purchaseAdsData } from './PurchaseAds.data';

const PurchaseAds = () => {
	return (
		<SectionWrapper>
			<SectionRow>
				<h1>Новые объявления покупки</h1>
				<SeeAllLink path={'/SellList'} mobileNone />
			</SectionRow>

			<FlatSwiper children={purchaseAdsData} />
		</SectionWrapper>
	);
};

export default PurchaseAds;
