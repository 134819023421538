export const offerData = [
	{
		title: 'Продажа квартир',
		params: [
			{ title: '1-комнатные', path: '/' },
			{ title: '2-комнатные', path: '/' },
			{ title: '3-комнатные', path: '/' },
			{ title: '4-комнатные', path: '/' },
			{ title: '5-комнатные и больше', path: '/' }
		]
	},
	{
		title: 'Выгодные предложения',
		params: [
			{ title: 'Ипотечный займ', path: '/' },
			{ title: 'Индивидуальное жилищное строительство', path: '/' },
			{ title: 'Дальневосточный гектар', path: '/' },
			{ title: 'Дальневосточная ипотека', path: '/' },
			{ title: 'Шаг за шагом', path: '/' }
		]
	},
	{
		title: 'Аренда квартир',
		params: [
			{ title: '1-комнатные', path: '/' },
			{ title: '2-комнатные', path: '/' },
			{ title: '3-комнатные', path: '/' },
			{ title: '4-комнатные', path: '/' },
			{ title: '5-комнатные и больше', path: '/' }
		]
	}
];
