import { FC } from 'react';
import styles from './Question.module.scss';
import { Icon } from '@iconify/react';

interface IQuestion {
	heading: string;
	views: number;
	date: string;
}
interface IQuestionsData {
	questions: IQuestion[];
}
const Question: FC<IQuestionsData> = ({ questions }) => {
	return (
		<div className={styles.questionsContainer}>
			<a href={'/AskQuestion'}>Задать вопрос</a>
			<h1>Вопросы</h1>
			<div>
				<div>
					<p>Все вопросы</p>
				</div>
				<div>
					<p>Популярные</p>
				</div>
				<div>
					<p>Кредитные каникулы</p>
				</div>
			</div>
			{questions.map(question => (
				<div className={styles.container}>
					<h1>{question.heading}</h1>
					<div>
						<div>
							<p>
								<span>
									<Icon icon={'ic:outline-remove-red-eye'} />
								</span>
								{question.views} просмотров
							</p>
						</div>
						<div>
							<p>
								<span>
									<Icon
										icon={'material-symbols:calendar-today-outline-rounded'}
									/>
								</span>{' '}
								{question.date}
							</p>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default Question;
