import { ReactComponent as IcHome } from '../../../../assets/images/icons/home.svg';
import { ReactComponent as IcHomePlus } from '../../../../assets/images/icons/home_plus.svg';
import { ReactComponent as IcHomeRuble } from '../../../../assets/images/icons/home_ruble.svg';

export const servicesData = [
	{
		path: '/',
		icon: <IcHome />,
		title: 'Арендное жильё в связи с мобилизацией'
	},
	{
		path: '/',
		icon: <IcHome />,
		title: 'Кредитные каникулы в связи с мобилизацией'
	},
	{
		path: '/',
		icon: <IcHomePlus />,
		title: 'Разместить объявление'
	},
	{
		path: '/',
		icon: <IcHomeRuble />,
		title: 'Выкуп арендного жилья'
	},
	{
		path: '/',
		icon: <IcHome />,
		title: 'Арендное жильё в связи с мобилизацией'
	},
	{
		path: '/',
		icon: <IcHomePlus />,
		title: 'Разместить объявление'
	}
];
