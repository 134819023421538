import { FC } from 'react';
import styles from './FormLabel.module.scss';
export interface IFormLabel {
	label: string;
}
const FormLabel: FC<IFormLabel> = ({ label }) => {
	return <h3 className={styles.label}>{label}</h3>;
};

export default FormLabel;
