import styles from './LogoDark.module.scss';
import logoDark from '../../assets/images/logo/logo-dark.svg';

const LogoDark = () => {
	return (
		<a href={'/'} className={styles.logo}>
			<img src={logoDark} alt='Логотип компании' />
		</a>
	);
};

export default LogoDark;
