import { FC, ReactNode } from 'react';
import styles from './ConditionRow.module.scss';
import classNames from 'classnames';

interface ConditionRowProps {
	children: ReactNode;
	className?: string;
}

const ConditionRow: FC<ConditionRowProps> = ({ children, className }) => {
	return <div className={classNames(styles.row, className)}>{children}</div>;
};

export default ConditionRow;
