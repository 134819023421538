export const requisitesData = [
	{ title: 'ИНН', description: '6501275110' },
	{ title: 'КПП', description: '650101001' },
	{ title: 'ОГРН', description: '1156501005066' },
	{ title: 'ОКПО', description: '24583064' },
	{ title: 'Расчетный счет', description: '40601810108020009543' },
	{ title: 'Банк', description: 'ФИЛИАЛ БАНКА ВТБ (ПАО) В Г. ХАБАРОВСКЕ' },
	{ title: 'Корр. счет', description: '30101810400000000727' },
	{
		title: 'Юридический/Фактический адрес',
		description:
			'693007, Сахалинская обл, Южно-Сахалинск г, ул. Дзержинского, д. 21, оф. 219.'
	},
	{ title: 'Телефон', description: '8 (4242) 312-532' },
	{ title: 'Генеральный директор', description: 'Бондарева Елена Валерьевна' }
];
