import SectionWrapper from '../../../../containers/SectionWrapper/SectionWrapper';
import SectionRow from '../../../../containers/SectionRow/SectionRow';
import SeeAllLink from '../../../../ui/SeeAllLink/SeeAllLink';
import NewsCard from '../../../../components/NewsCard/NewsCard';
import NewsContainer from '../../../../containers/NewsContainer/NewsContainer';
import { newsData } from './News.data';
import { v4 as uuidv4 } from 'uuid';

const News = () => {
	return (
		<SectionWrapper>
			<SectionRow>
				<h1>Подтверждаем слова - делом</h1>
				<SeeAllLink path={'/allNews'} mobileNone />
			</SectionRow>

			<NewsContainer>
				{newsData.map(card => {
					return (
						<NewsCard
							key={uuidv4()}
							img={card.img}
							title={card.title}
							newsContent={card.newsContent}
							date={card.date}
							path={card.path}
						/>
					);
				})}
			</NewsContainer>

			<SeeAllLink path={'/allNews'} desktopNone />
		</SectionWrapper>
	);
};

export default News;
