import Main from '../../containers/Main/Main';
import Form from '../../ui/Form/Form';
import { useState } from 'react';

const NewAdvert = () => {
	const [isRent, setIsRent] = useState(true);
	return (
		<Main>
			<Form>
				<Form.Heading heading={'Новое объявление'} />
				<Form.ToggleButton
					label={'Тип сделки'}
					options={['Аренда', 'Продажа']}
					clickFunction={() => setIsRent(prev => !prev)}
				/>
				{isRent && (
					<Form.ToggleButton
						label={'Тип аренды'}
						options={['Длительно', 'Посуточно']}
					/>
				)}
				<Form.Input label={'Адрес'} type={'text'} required />
				<Form.Input label={'Жилой комплекс'} type={'text'} />
				<Form.Label label={'Информация об объекте'} />
				<Form.Input
					label={'Площадь общая'}
					type={'text'}
					description={'Площадь помещения или строения, в кв.м.'}
					required
				/>
				<Form.Input label={'Площадь жилая'} type={'text'} />
				<Form.Input label={'Площадь кухни'} type={'text'} />
				<Form.Input label={'Количество комнат'} type={'text'} required />
				<Form.DropDown
					label={'Планировка'}
					options={[
						{ label: '102 серия', value: '102 серия' },
						{ label: '138 серия', value: '138 серия' },
						{ label: '306 серия', value: '306 серия' },
						{ label: '97 серия', value: '97 серия' },
						{ label: '97-C серия', value: '97-С серия' },
						{ label: 'Студия', value: 'Студия' },
						{ label: 'Гостинка', value: 'Гостинка' },
						{ label: 'Сталинка', value: 'Сталинка' },
						{ label: 'Хрущевка', value: 'Хрущевка' },
						{ label: 'Брежневка', value: 'Брежневка' },
						{ label: 'Индивидуальная', value: 'Индивидуальная' },
						{ label: 'Новостройка', value: 'Новостройка' }
					]}
				/>
				<Form.Input
					label={'Этаж'}
					type={'text'}
					description={'Только номер этажа. Для цокола: -1'}
					required
				/>
				<Form.Input label={'Этажность'} type={'text'} required />
				<Form.DropDown
					label={'Материал стен'}
					options={[
						{ label: 'Монолит', value: 'Монолит' },
						{ label: 'Панель', value: 'Панель' },
						{ label: 'Каркас', value: 'Каркас' },
						{ label: 'Брус', value: 'Брус' },
						{ label: 'Кирпич', value: 'Кирпич' },
						{ label: 'Шлакоблок', value: 'Шлакоблок' },
						{ label: 'Газо-пеноблок', value: 'Газо-пеноблок' }
					]}
				/>
				<Form.Input label={'Высота потолков'} type={'text'} />
				<Form.Input
					label={'Год постройки'}
					type={'text'}
					placeholder={'Не указано'}
				/>
				<Form.Label label={'Дополнительные параметры'} />
				<Form.DropDown
					label={'Парковка'}
					options={[
						{ label: 'Открытая', value: 'Открытая' },
						{ label: 'Закрытая', value: 'Закрытая' },
						{ label: 'Подземная', value: 'Подземная' }
					]}
					required
				/>
				<Form.DropDown
					label={'Ремонт'}
					options={[
						{ label: 'Требуется', value: 'Требуется' },
						{ label: 'Косметический', value: 'Косметический' },
						{ label: 'Евро', value: 'Евро' },
						{ label: 'Дизайнерский', value: 'Дизайнерский' }
					]}
				/>
				<Form.DropDown
					label={'Сторона света'}
					options={[
						{ label: 'Юг', value: 'Юг' },
						{ label: 'Север', value: 'Север' },
						{ label: 'Запад', value: 'Запад' },
						{ label: 'Восток', value: 'Восток' }
					]}
				/>
				<Form.DropDown
					label={'Балкон'}
					options={[
						{ label: 'Нет', value: 'Нет' },
						{ label: 'Один', value: 'Один' },
						{ label: 'Лоджия', value: 'Лоджия' },
						{ label: 'Больше одного', value: 'Больше одного' }
					]}
				/>
				<Form.DropDown
					label={'Санузел'}
					options={[
						{ label: 'Совмещенный', value: 'Совмещенный' },
						{ label: 'Раздельный', value: 'Раздельный' }
					]}
				/>
				<Form.Input
					label={'Коммуникации'}
					type={'checkbox'}
					options={['Телефон', 'Кабельное ТВ', 'Интернет']}
				/>
				<Form.ToggleButton
					label={'Пассажирских лифтов'}
					options={['Нет', '1', '2', '3', '4']}
				/>
				<Form.ToggleButton
					label={'Грузовых лифтов'}
					options={['Нет', '1', '2', '3', '4']}
				/>
				<Form.DropDown
					label={'Газ'}
					options={[
						{ label: 'Нет', value: 'Нет' },
						{ label: 'Есть', value: 'Есть' }
					]}
				/>
				<Form.DropDown
					label={'Охрана'}
					options={[
						{ label: 'Нет', value: 'Нет' },
						{ label: 'Есть', value: 'Есть' }
					]}
				/>
				<Form.Input
					type={'text'}
					label={'Цена'}
					money
					required
					checkbox={'Возможна ипотека'}
					description={'Стоимость объекта или аренды всего объекта, в рублях'}
				/>
				<Form.Input
					label={'Текст объявления'}
					type={'textarea'}
					description={'Не оставляйте контактные данные в тексте объявления.'}
				/>
				<Form.Input
					label={'Срок размещения'}
					type={'text'}
					required
					description={
						'Максимальный срок размещения (или следующего продления): 2 месяца'
					}
				/>
				<Form.Input
					label={'Видео-обзор'}
					type={'text'}
					placeholder={'Ссылка на видео-ролик YouTube.com'}
				/>
				<Form.Input
					label={'Фотографии'}
					type={'file'}
					description={
						'Размер не менее 640х480 пикселей. Общее количество до 10 файлов'
					}
				/>
				<Form.Input
					label={'Документы о праве собственности'}
					type={'file'}
					file
				/>
				<Form.Button>Завершить</Form.Button>
			</Form>
		</Main>
	);
};

export default NewAdvert;
