import Main from '../../containers/Main/Main';
import Form from '../../ui/Form/Form';
const Register = () => {
	return (
		<Main>
			<Form>
				<Form.Heading
					heading={'Регистрация'}
					subHeading={'Уже есть аккаунт?'}
					link={'/auth'}
					linkLabel={'Войти'}
				/>
				<Form.Input label={'Электронная почта'} type={'text'} />
				<Form.Input label={'Имя'} type={'text'} />
				<Form.Input label={'Фамилия'} type={'text'} />
				<Form.Input label={'Отчество'} type={'text'} />
				<Form.Input label={'Дата рождения'} type={'data'} />
				<Form.Input label={'Пароль'} type={'password'} />
				<Form.Input label={'Повторите пароль'} type={'password'} />
				<Form.Button>Создать аккаунт</Form.Button>
			</Form>
		</Main>
	);
};

export default Register;
