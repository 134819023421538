import { FC, ReactNode } from 'react';
import styles from './Row.module.scss';
import classNames from 'classnames';

interface RowProps {
	children: ReactNode;
	className?: string;
}

interface RowContentProps {
	children: ReactNode;
}

interface RowHeader {
	children: ReactNode;
}

const Row: FC<RowProps> & { Content: FC<RowContentProps> } & {
	Header: FC<RowHeader>;
} = ({ children, className }) => {
	return (
		<div className={classNames(styles.wrapper, className)}>{children}</div>
	);
};

Row.Content = ({ children }) => (
	<div className={styles.content}>{children}</div>
);

Row.Header = ({ children }) => <div className={styles.header}>{children}</div>;

export default Row;
