import styles from './Navbar.module.scss';
import Container from '../../containers/Container/Container';
import LogoDark from '../../ui/LogoDark/LogoDark';
import { navigationData } from '../../core/data/navigation.data';
import Burger from './Burger';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setMenuActive, setId } from '../../core/store/slices/MenuSlice';
import { useAppSelector } from '../../core/hooks/storeHook';
import classNames from 'classnames';
import { useScrollbarWidth } from '../../core/hooks/useScrollbarWidth';

const Navbar = () => {
	const [isVisible, setIsVisible] = useState<boolean>(true);
	const [prevScrollPos, setPrevScrollPos] = useState<number>(window.scrollY);
	const { id, isMenuActive } = useAppSelector(state => state.MenuSlice);
	const dispatch = useDispatch();
	const scrollbarWidth = useScrollbarWidth();

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos: number = window.scrollY;
			const isScrollingDown: boolean = currentScrollPos > prevScrollPos;

			if (currentScrollPos >= 200) {
				setIsVisible(!isScrollingDown);
				setPrevScrollPos(currentScrollPos);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => window.removeEventListener('scroll', handleScroll);
	}, [prevScrollPos, isVisible]);

	const toggleMenu: MouseEventHandler<HTMLAnchorElement> = event => {
		const target = event.target as HTMLAnchorElement;
		const menuId = target.dataset.id;
		if (menuId) event.preventDefault();

		if (menuId) {
			dispatch(setMenuActive(true));
			dispatch(setId(menuId));
			document.body.classList.add('lock');
			document.body.style.paddingRight = scrollbarWidth + 'px';
		}
	};

	return (
		<nav
			style={{ paddingRight: isMenuActive ? scrollbarWidth + 'px' : '' }}
			className={classNames(styles.navbar, isVisible && styles.visible)}
		>
			<Container className={styles.row}>
				<div className={styles.group}>
					<Burger />
					<LogoDark />
				</div>

				<ul className={styles.navList}>
					{navigationData.length > 0 &&
						navigationData.map((link, index) => {
							return (
								<li key={index}>
									<a
										className={link.id === id ? styles.active : ''}
										onClick={toggleMenu}
										data-id={link.id}
										href={link.path}
									>
										{link.title}
									</a>
								</li>
							);
						})}
				</ul>

				<div className={styles.content}>
					<li>
						<a href='/newAdvert' className={styles.advtBtn}>
							Разместить объявление
						</a>
					</li>
					<li>
						<a href='/auth' className={styles.loginBtn}>
							Войти
						</a>
					</li>
				</div>
			</Container>
		</nav>
	);
};

export default Navbar;
