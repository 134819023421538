import { FC } from 'react';
import styles from './SwiperPrev.module.scss';
import classNames from 'classnames';
import { ReactComponent as IcArrow } from '../../assets/images/icons/arrow.svg';

interface SwiperPrevProps {
	onClick: () => void;
	disabled: boolean;
	className?: string;
	position?: number;
}

const SwiperPrev: FC<SwiperPrevProps> = ({
	onClick,
	disabled,
	className,
	position
}) => {
	return (
		<button
			disabled={disabled}
			className={classNames(styles.prev, className)}
			onClick={onClick}
			style={{ top: `${position}%`, transform: `translateY(-${position}%)` }}
		>
			<IcArrow />
		</button>
	);
};

export default SwiperPrev;
