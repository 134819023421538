import styles from './Heading.module.scss';
import Price from '../Price/Price';

const Heading = () => {
	return (
		<div className={styles.heading}>
			<h1>1-к. квартира, 44 м², 3/5 эт.</h1>

			<div className={styles.verified}>
				<p>Проверено модерацией</p>
			</div>

			<Price className={styles.priceMobile} />
			<div className={styles.address}>
				<p>Сахалинская область, Южно-Сахалинск, просп. Мира, 247А</p>
				<p>
					<span>ЖК Мичуринский </span>
				</p>
			</div>
		</div>
	);
};

export default Heading;
