export const questionsData = [
	{
		heading:
			'Здравствуйте. Дальневосточная ипотека распространяется Ново-Александровку?',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading: 'Шаг за шагом',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading: 'Сертификат',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading: 'Арендное жилье жёнам мобилизованных',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading: 'Переоформление договора с коммерческого найма на соц найм',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading: 'Выкуп арендного жилья',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading: 'Коммерческий найм',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading: 'Коммерческий найм',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading: 'Дальневосточная сельская ипотека',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading:
			'Выплата 450 тысяч рублей на погашение ипотечного кредита в связи с рождением третьего ребенка.',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading:
			'Здравствуйте. Дальневосточная ипотека распространяется Ново-Александровку?',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading:
			'Здравствуйте. Дальневосточная ипотека распространяется Ново-Александровку?',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading: 'Переоформление договора с коммерческого найма на соц найм',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading: 'Выкуп арендного жилья',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading: 'Коммерческий найм',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading: 'Коммерческий найм',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading: 'Дальневосточная сельская ипотека',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading:
			'Выплата 450 тысяч рублей на погашение ипотечного кредита в связи с рождением третьего ребенка.',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading:
			'Здравствуйте. Дальневосточная ипотека распространяется Ново-Александровку?',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading: 'Переоформление договора с коммерческого найма на соц найм',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading: 'Выкуп арендного жилья',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading: 'Коммерческий найм',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading: 'Коммерческий найм',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading: 'Дальневосточная сельская ипотека',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading:
			'Выплата 450 тысяч рублей на погашение ипотечного кредита в связи с рождением третьего ребенка.',
		views: 658,
		date: '10.11.2022'
	},
	{
		heading:
			'Здравствуйте. Дальневосточная ипотека распространяется Ново-Александровку?',
		views: 658,
		date: '10.11.2022'
	}
];
