import { ReactNode } from 'react';
import FormContainer from '../../containers/FormContainer/FormContainer';
import styles from './Form.module.scss';
import FormButton, { IButton } from './components/FormButton/FormButton';
import Input, { IInput } from './components/Input/Input';

import FormHeading, {
	IFormHeading
} from './components/FormHeading/FormHeading';
import FormLabel, { IFormLabel } from './components/FormLabel/FormLabel';
import FormToggleButton, {
	IToggleInput
} from './components/FormToggleButton/FormToggleButton';
import FormDropDown, {
	IDropDown
} from './components/FormDropDown/FormDropDown';
import TextEditor, { ITextEditor } from './components/TextEditor/TextEditor';
interface IForm {
	children: ReactNode;
}

const Form = ({ children }: IForm) => (
	<FormContainer>
		<form className={styles.form}>{children}</form>
	</FormContainer>
);

Form.Input = ({
	label,
	type,
	required,
	description,
	file,
	options,
	checkbox,
	placeholder,
	money
}: IInput) => (
	<Input
		options={options}
		type={type}
		label={label}
		required={required}
		file={file}
		description={description}
		checkbox={checkbox}
		money={money}
		placeholder={placeholder}
	/>
);

Form.Button = ({ children }: IButton) => <FormButton>{children}</FormButton>;

Form.Heading = ({ heading, link, subHeading, linkLabel }: IFormHeading) => (
	<FormHeading
		heading={heading}
		link={link}
		linkLabel={linkLabel}
		subHeading={subHeading}
	/>
);

Form.ToggleButton = ({ options, label, clickFunction }: IToggleInput) => (
	<FormToggleButton
		label={label}
		options={options}
		clickFunction={clickFunction}
	/>
);
Form.Label = ({ label }: IFormLabel) => <FormLabel label={label} />;
Form.TextEditor = ({ onChange, placeholder, value, label }: ITextEditor) => (
	<TextEditor
		value={value}
		placeholder={placeholder}
		onChange={onChange}
		label={label}
	/>
);
Form.DropDown = ({
	label,
	description,
	options,
	placeholder,
	required,
	checkbox
}: IDropDown) => (
	<FormDropDown
		options={options}
		required={required}
		label={label}
		description={description}
		placeholder={placeholder}
		checkbox={checkbox}
	/>
);
export default Form;
