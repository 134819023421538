import { createBrowserRouter } from 'react-router-dom';
import Home from '../../../screens/Home/Home';
import Auth from '../../../screens/Auth/Auth';
import Register from '../../../screens/Register/Register';
import NewAdvert from '../../../screens/NewAdvert/NewAdvert';
import AllNews from '../../../screens/AllNews/AllNews';
import PhotoReports from '../../../screens/PhotoReports/PhotoReports';
import News from '../../../screens/News/News';
// import Faq from '../../../screens/Faq/Faq';
import SaleImmovable from '../../../screens/SaleImmovable/SaleImmovable';
import AgencyHouses from '../../../screens/AgencyHouses/AgencyHouses';
import HomeBuyout from '../../../screens/HomeBuyout/HomeBuyout';
import StepByStep from '../../../screens/StepByStep/StepByStep';
import EasternMortgage from '../../../screens/EasternMortgage/EasternMortgage';
import AskQuestion from '../../../screens/Faq/AskQuestion/AskQuestion';
import Documents from '../../../screens/Documents/Documents';
import AboutCompany from '../../../screens/AboutCompany/AboutCompany';
import Questions from '../../../screens/Faq/Questions';
import SellAdvert from '../../../screens/SellAdvert/SellAdvert';
import RentAdvert from '../../../screens/RentAdvert/RentAdvert';
import AllRentList from '../../../screens/AllRentList/AllRentList';

export const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />
	},
	{
		path: '/auth',
		element: <Auth />
	},
	{
		path: '/questions',
		element: <Questions />
	},
	{
		path: '/askQuestion',
		element: <AskQuestion />
	},
	{
		path: '/register',
		element: <Register />
	},
	{
		path: '/newAdvert',
		element: <NewAdvert />
	},
	{
		path: '/allNews',
		element: <AllNews />
	},
	{
		path: '/allPhotoReports',
		element: <PhotoReports />
	},
	{
		path: '/news',
		element: <News />
	},
	{
		path: '/questions',
		element: <Questions />
	},
	{
		path: '/askQuestion',
		element: <AskQuestion />
	},
	{
		path: '/documents',
		element: <Documents />
	},
	{
		path: '/aboutCompany',
		element: <AboutCompany />
	},
	{
		path: '/saleImmovable',
		element: <SaleImmovable />
	},
	{
		path: '/sellAdvert',
		element: <SellAdvert />
	},
	{
		path: '/rentAdvert',
		element: <RentAdvert />
	},
	{
		path: '/rentList',
		element: <AllRentList />
	},
	{
		path: '/homeBuyout',
		element: <HomeBuyout />
	},
	{
		path: '/stepByStep',
		element: <StepByStep />
	},
	{
		path: '/easternMortgage',
		element: <EasternMortgage />
	},
	{
		path: '/sellAdvert',
		element: <SellAdvert />
	}
	// {
	// 	path: '/rentAdvert',
	// 	element: <RentAdvert data={rentAdvertData} />
	// },
]);
