import { FC } from 'react';
import styles from './Phone.module.scss';
import classNames from 'classnames';

interface PhoneProps {
	phone: string;
	className?: string;
}

const Phone: FC<PhoneProps> = ({ phone, className }) => {
	return (
		<a className={classNames(styles.phone, className)} href={`tel: ${phone}`}>
			{phone}
		</a>
	);
};

export default Phone;
