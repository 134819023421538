import { FC } from 'react';
import styles from './Offer.module.scss';
import { v4 as uuidv4 } from 'uuid';

interface OfferCardProps {
	title: string;
	params: any;
}

const OfferCard: FC<OfferCardProps> = ({ title, params }) => {
	return (
		<div className={styles.offerCard}>
			<h3>{title}</h3>

			<ul>
				{params.map((item: any) => {
					return (
						<li key={uuidv4()}>
							<a href={item.path}>{item.title}</a>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default OfferCard;
