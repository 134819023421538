import styles from './Map.module.scss';

const Map = () => {
	return (
		<div className={styles.container}>
			<h1>Расположение</h1>
			<p>Сахалинская область, Южно-Сахалинск, просп. Мира, 247А</p>
			<div className={styles.map} />
		</div>
	);
};

export default Map;
