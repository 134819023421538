import { useRef, useState } from 'react';
import styles from './LastNewsCard.module.scss';
import SectionWrapper from '../../../../containers/SectionWrapper/SectionWrapper';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperPrev from '../../../../components/SwiperPrev/SwiperPrev';
import SwiperNext from '../../../../components/SwiperNext/SwiperNext';
import Wrapper from '../../../../containers/Wrapper/Wrapper';
import { lastNewsData } from './LastNews.data';
import LastNewsCard from './LastNewsCard';

const LastNews = () => {
	const swiperRef = useRef<SwiperCore>();

	const [swiperNavigationAllowed, setSwiperNavigationAllowed] = useState<any>({
		prevButtonDisabled: true,
		nextButtonDisabled: false
	});

	return (
		<SectionWrapper pbNone>
			<h1 className={'mb-4'}>Последние новости</h1>

			<Wrapper>
				<Swiper
					spaceBetween={24}
					slidesPerView={'auto'}
					scrollbar={{ draggable: true }}
					onBeforeInit={(swiper: SwiperCore | undefined) => {
						swiperRef.current = swiper;
					}}
					onReachEnd={() =>
						setSwiperNavigationAllowed({
							...swiperNavigationAllowed,
							nextButtonDisabled: true
						})
					}
					onReachBeginning={() =>
						setSwiperNavigationAllowed({
							...swiperNavigationAllowed,
							prevButtonDisabled: true
						})
					}
					onSlideChange={() =>
						setSwiperNavigationAllowed({
							prevButtonDisabled: swiperRef?.current?.isBeginning,
							nextButtonDisabled: swiperRef?.current?.isEnd
						})
					}
				>
					{lastNewsData.map((item, index) => {
						return (
							<SwiperSlide style={{ width: 'auto' }} key={index}>
								<LastNewsCard
									image={item.image}
									title={item.title}
									date={item.date}
									path={item.path}
								/>
							</SwiperSlide>
						);
					})}
				</Swiper>

				<SwiperPrev
					onClick={() => swiperRef.current?.slidePrev()}
					disabled={swiperNavigationAllowed.prevButtonDisabled}
					className={styles.pos}
				/>
				<SwiperNext
					onClick={() => swiperRef.current?.slideNext()}
					disabled={swiperNavigationAllowed.nextButtonDisabled}
					className={styles.pos}
				/>
			</Wrapper>
		</SectionWrapper>
	);
};

export default LastNews;
