import { FC } from 'react';
import styles from './SwiperNext.module.scss';
import { ReactComponent as IcArrow } from '../../assets/images/icons/arrow.svg';
import classNames from 'classnames';

interface SwiperPrevProps {
	onClick: () => void;
	disabled?: boolean;
	className?: string;
	position?: number;
}

const SwiperPrev: FC<SwiperPrevProps> = ({
	onClick,
	disabled,
	className,
	position
}) => {
	return (
		<button
			disabled={disabled}
			className={classNames(styles.next, className)}
			onClick={onClick}
			style={{ top: `${position}%`, transform: `translateY(-${position}%)` }}
		>
			<IcArrow />
		</button>
	);
};

export default SwiperPrev;
