import { FC } from 'react';
import styles from './MainAccordion.module.scss';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from 'react-accessible-accordion';

interface MainAccordionProps {
	data: { title: string; content: string }[];
}

const MainAccordion: FC<MainAccordionProps> = ({ data }) => {
	return (
		<Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
			{data.map((item, index) => {
				return (
					<AccordionItem className={styles.item} key={index}>
						<AccordionItemHeading>
							<AccordionItemButton className={styles.button}>
								{item.title}
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel
							// @ts-ignore
							dangerouslySetInnerHTML={{ __html: item.content }}
							className={styles.content}
						></AccordionItemPanel>
					</AccordionItem>
				);
			})}
		</Accordion>
	);
};

export default MainAccordion;
