import { EditorApi, useEditor } from './useEditor';
import { createContext, FC, ReactNode, useContext } from 'react';

const TextEditorContext = createContext<EditorApi | undefined>(undefined);

export const useEditorApi = () => {
	const context = useContext(TextEditorContext);
	if (context === undefined) {
		throw new Error('useEditorApi must be used within TextEditorProvider');
	}

	return context;
};

export const TextEditorProvider: FC<{ children: ReactNode }> = ({
	children
}) => {
	const editorApi = useEditor();

	return (
		<TextEditorContext.Provider value={editorApi}>
			{children}
		</TextEditorContext.Provider>
	);
};
